.career-item
	@apply flex items-center tsn hover:bg-neutral-50 xl:gap-5 xl:px-5
	.icon
		@apply size-20 flex-center
	.title
		@apply body-18 font-bold text-primary-2 uppercase
	.caption
		@apply flex-1 py-2

.box-career-slider
	.swiper-slide
		@apply border-l border-t border-neutral-100 md:flex md:flex-wrap
		.career-item
			@apply border-b border-r border-neutral-100 md:w-1/2

.career-form
	@apply flex flex-col gap-5 p-5 md:px-10 md:flex-row xl:px-15 xl:rem:min-h-[580px] xl:gap-10
	.left
		@apply md:flex-1
		@screen xl
			@apply pt-10 pl-10
	.right
		@apply md:rem:w-[580px] md:mt-5 xl:mt-15
	[class*='sub-header-24']
		@media (max-width: 767.98px)
			@apply text-[18px]
