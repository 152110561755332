.hospital-item
	@apply flex flex-col md:flex-row md:odd:flex-row-reverse
	@media (max-width: 767.98px)
		@apply flex-col-reverse
	.image
		@apply aspect-[402/291] md:w-1/2
		@media (max-width: 575.98px)
			@apply w-full h-[200px]
			img
				@apply w-full h-full object-cover
	.caption
		@apply p-4 bg-primary-2 tsn md:p-8 xl:p-15 md:flex-1
		*
			@apply text-white tsn
	.title
		@apply section-header-30 font-bold uppercase
		@media (max-width: 767.98px)
			@apply text-[20px] leading-[1.2]
	.line
		@apply mt-2 mb-3 border-t-2 border-white w-10 xl:mt-3 xl:mb-5
		@media (max-width: 767.98px)
			@apply border-t-[2px] my-1
	.sub-title
		@apply text-15px font-bold
	.desc
		@apply mt-5 text-15px font-normal
		@media (max-width: 575.98px)
			@apply hidden
	.button
		@apply mt-5
		a
			@apply uppercase text-base font-normal
	&:hover
		.caption
			@apply bg-primary-1

.hospital-section
	.full-content
		@apply text-neutral-700
		@apply prose-spacing-0
