.box-profile-detail
	@apply p-4 border-2 border-neutral-200 sticky top-30 xl:p-5
	@media (max-width: 767.98px)
		@apply border-[2px]
	table
		@apply w-full
	td
		@apply body-16 font-normal text-black py-2 border-b border-neutral-300
		@apply last:text-right
	tr
		&:last-child
			td
				@apply py-3

.post-navigation
	@apply flex justify-between gap-5
	> *
		@apply relative flex items-center gap-3 xl:gap-5
		&.next
			@apply flex-row-reverse
	.icon
		@apply size-11 flex-center border border-neutral-300 text-neutral-300 text-lg
		@apply transition-all duration-300
	.caption
		@apply flex flex-col text-[10px] text-neutral-300
		@screen md
			@apply body-14
	.title
		@apply text-neutral-300
		@apply text-[12px]
		@screen md
			@apply body-16
	.link
		background: none
		&:hover
			.icon
				@apply border-primary-1 bg-primary-1 text-white

.find-doctor-detail-section
	.full-content
		@apply text-black
	.container
		>.row
			@media (max-width: 1023.98px)
				@apply flex-col-reverse gap-y-5

.our-doctor-section
	.expand-btn
		@media (min-width: 768px)
			display: none !important
	.swiper-relative
		.swiper
			@apply xl:-m-5
			@apply xl:p-5
	.doctor-item
		&::before
			box-shadow: 3px 0px calc(10/1920*100rem) rgba(0, 0, 0, 0.2)
	.swiper-page-button
		@apply xl:mt-0 xl:top-[0.3rem]
	.swiper-button
		@apply flex-center gap-3 mt-5 xl:mt-0
		> *
			@apply size-11 text-lg border-2 border-neutral-300 rounded-none bg-transparent bg-none text-neutral-300
	@screen xl
		.swiper-button
			@apply absolute right-0 bottom-[calc(100%+(60/1920*100rem))]
	&.button-position-2
		@screen xl
			.swiper-button
				@apply right-0 bottom-[calc(100%+(20/1920*100rem))]
	&.button-position-3
		@screen xl
			.swiper-button
				@apply right-0 bottom-[calc(100%+(8/1920*100rem))] top-[unset]
