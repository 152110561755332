.customer-services
	@media (max-width: 1023.98px)
		.list
			@media (max-width: 1023.98px)
				@apply grid-cols-1 gap-y-10
		.bn-1
			@apply flex-row gap-4
			.img
				@apply w-[120px] md:w-[200px]
			.info
				@apply pt-0 mt-0
			.des
				@apply flex-auto
