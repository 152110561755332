+form-text
	@apply bg-white border border-white pl-5 pr-10 py-2 rounded-none text-sm font-normal leading-normal text-primary-2 w-full bg-no-repeat h-10 m-0
	@apply focus:outline-none focus:ring-2 focus:ring-secondary-3 focus:ring-offset-0
	@apply placeholder:uppercase placeholder:text-neutral-300
	@apply bg-neutral-200
	&:focus
		@apply bg-white

+form-input
	@apply placeholder:text-neutral-300
	// @apply placeholder-shown:bg-white
	@apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900
	@apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900
	&:focus
		@apply bg-white
select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23cfcfcf' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>")
	@apply bg-right appearance-none

select[multiple]
	@apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none

textarea
	@apply h-[120px] py-2 xl:h-40 block

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-primary-2 flex-center gap-2 text-sm text-white px-3 py-2 cursor-pointer xl:pr-10 whitespace-nowrap
		i
			@apply text-lg

.attach-file
	@apply flex items-center
	.file-preview
		@apply px-3 text-sm text-neutral-700 xl:px-5
		@apply w-full

input[type='checkbox']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f14a']

input[type='radio']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f192']

.form-group
	@apply relative
	> label
		@apply relative block text-base font-normal leading-normal text-neutral-400 mb-2
		@apply font-medium
		@apply section-header-32 font-bold text-primary-2 mb-3
	[class*='fa-ex']
		@apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal
	.attach-file
		@apply bg-neutral-50 z-1
		@apply w-full mt-5
	button[type="submit"], input[type="submit"]
		@apply px-5 py-3 h-10 tsn xl:h-12 xl:px-10
	&.form-rating
		> label
			@apply mb-0 text-base font-normal text-[#545454] xl:text-xl

.gl-star-rating--stars
	@apply flex items-center gap-[2px] xl:rem:gap-[10px]
	span
		@apply flex-center
		&::before
			@apply font-awesome content-['\f005'] font-bold text-xl text-primary-2/30 xl:text-2xl
		&.gl-active, &.gl-selected
			@apply before:text-primary-1

input[type=number]
	-moz-appearance: textfield
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

.invalid-feedback
	@apply text-sm text-primary-1 font-medium mt-1
.warning-feedback
	@apply text-sm text-primary-2 font-medium mt-1
.contact-success-message
	@apply text-sm text-green-700 font-medium mt-1

.form-select-dropdown
	@apply relative
	&.active
		.easy-dropdown-list
			@apply opacity-100 pointer-events-auto
	input
		background-image: url('data:image/svg+xml,<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="white"/></svg>')
		background-position: center right rr(16)
		background-size: rr(16) rr(9)
		height: auto !important
		@apply bg-no-repeat block w-full
		@apply placeholder:text-inherit
		&:read-only
			@apply bg-inherit
		&:disabled
			@apply opacity-70
	.fake-input
		@apply whitespace-nowrap overflow-hidden
		@apply absolute inset-0
		@apply bg-white text-primary-2 invisible
		@apply flex items-center
	.easy-dropdown-list
		max-height: rr(280)
	&.active-marquee
		.fake-input
			@apply visible
