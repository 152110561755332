.same-custom-fancybox
	&.fancybox__container
		.fancybox__content
			max-width: rr(800)
			@apply p-0 w-full rounded-2

		[data-fancybox-close]
			@apply hidden
		[class*='btn']
			@apply rounded-1

.popup-error,.popup-form
	&.fancybox__container
		.fancybox__content
			@apply py-6 px-4 sm:px-8
.popup-success
	&.fancybox__container
		.fancybox__content
			max-width: rr(920)

.popup-form
	.form-group
		input,select,textarea
			@apply border-primary-2
		select
			background-size: rr(28)
			background-position: center right rr(4)
	.field-required
		@apply text-primary-1
