.footer-pdr
	@apply md:pr-7 lg:pr-5 xl:pr-7

.footer-logo
	@media (max-width: 767.98px)
		@apply w-[200px]

.footer-info
	> *
		@apply mb-5 last:mb-0
	@media (max-width: 767.98px)
		@apply text-[13px]

.footer-title
	@apply body-18 font-bold text-white pb-3 relative
	&::before
		content: ''
		@apply absolute left-0 bottom-0 w-11 bg-white/75 h-1 pointer-events-none

.footer-item
	@apply flex gap-3 relative
	.image
		@apply aspect-[82/58] w-[82px]
		@media (max-width: 767.98px)
			@apply w-[33%] aspect-auto h-[58px]
			img
				@apply w-full h-full object-cover
		// @media (max-width: 767.98px)
		// 	@apply w-[33%] aspect-auto
		// 	img
		// 		@apply w-full h-auto object-contain
	.caption
		@apply flex-1
	.title
		@apply body-14 font-bold text-white line-clamp-2 capitalize
	.news-date
		@apply mb-1

.footer-top
	@apply xl:pb-5

.footer-bot
	.footer-wrap
		@apply flex items-center flex-wrap justify-between text-center gap-3 xl:gap-5

.footer-menu
	@apply flex flex-wrap gap-3 xl:gap-5
	a
		@apply label-12 font-normal text-white hover-underline uppercase
	li
		@apply flex-center
		&.active
			a
				@apply hover-underline-active
