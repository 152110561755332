.pagination
	@apply flex-center flex-wrap gap-3 mt-8 xl:mt-10
	a
		@apply flex-center size-8 text-base font-bold text-[#cecece] bg-white rounded-none border border-[#999999]/15
		@apply hover:bg-neutral-100
		&.active
			@apply bg-black/10 border-transparent
	@screen xl
		.prev
			@apply mr-2
		.next
			@apply ml-2
