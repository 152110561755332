.box-partner
	@apply flex flex-col gap-5 lg:flex-row lg:items-center lg:justify-between
	// .left
	// 	@apply lg:rem:max-w-[788px]

.partner-1-section
	@apply xl:py-12
	.site-desc
		@apply text-justify

.partner-item
	@apply border border-neutral-200 bg-white px-4 py-2

.partner-2-category
	.head
		@apply border-b border-primary-2
	.category-title
		@apply sub-header-20 font-bold text-white bg-primary-2 px-5 py-2 h-12 relative w-max -mb-px
		&::after
			content: ''
			@apply size-0 block absolute left-full top-0 pointer-events-none rem:border-b-[48px] border-b-primary-2 rem:border-r-[26px] border-r-transparent
	.partner-list
		@apply grid grid-cols-2 mt-10 rem:gap-[30px] md:grid-cols-3 lg:grid-cols-4
	.partner-title
		@apply sub-header-20 font-bold text-primary-1
	.partner-group
		@apply first:mt-3

.partner-2-section
	.box-partner
		@apply items-start
		.left
			@apply lg:rem:max-w-[855px]
	.category-list
		@apply grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:rem:-mx-[10px] xl:rem:gap-x-[43px]

.category-item
	@apply flex flex-col gap-2 tsn relative
	@apply xl:rem:p-[10px]
	.image
		@apply aspect-[355/230]
	.caption
		@apply flex-1
	.title
		@apply body-14 font-bold text-primary-2 line-clamp-3 rem:h-[54px] border-b border-neutral-200
	.desc
		@apply rem:mt-[9px] body-14 font-normal text-neutral-700 line-clamp-3
	.button
		@apply mt-3 tsn xl:mt-20
		> a
			@apply xl:-translate-y-10
	&:hover
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08)
		@apply bg-white
		.button
			> a
				@apply xl:translate-y-0 xl:gap-10 xl:text-primary-2
