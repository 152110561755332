.specialist-detail-1
	.searchbox
		input
			@apply h-auto
			@apply text-15px text-primary-2
			@apply border border-primary-1
			@apply h-10 normal-case
			@apply placeholder:text-primary-2 placeholder:normal-case
		button
			@apply h-full right-0
	.swiper-page-button
		@screen xl
			@apply gap-3 absolute top-0 right-0
	.row
		.col
			@apply relative

	@media (max-width: 1199.98px)
		.box-specialist-category
			top: 101px
			height: calc(100vh - 101px)
			width: calc(100% - calc(32/1920*100rem))
			@apply absolute top-full left-1/2 -translate-x-1/2 z-[100]
			@apply flex flex-col max-h-[200px] overflow-auto
			@apply opacity-0 pointer-events-none
			@apply transition-all duration-300
			@apply p-0 bg-white border border-neutral-100
			@media (min-width: 576px)
				top: 60px
				height: calc(100vh - 60px)
			&.active
				@apply opacity-100 pointer-events-auto
			.title
				@apply hidden
		.category-list
			@apply mt-0 pb-0
			li
				&.active a,&:hover a,a
					@apply px-4
	.toggle-category
		@media (max-width: 767.98px)
			@apply py-1
	.container
		>[class*='section-header-32']
			@media (max-width: 767.98px)
				@apply hidden
.specialist-detail-3
	.swiper-page-button
		@screen xl
			@apply absolute right-0
