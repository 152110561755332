.box-doctor-filter
	@apply bg-primary-2 p-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:gap-10 xl:py-5
	select
		@apply text-primary-1/40 uppercase
		option
			@apply text-primary-2 font-medium

.doctor-item
	@apply relative flex md:flex-col z-1 h-full
	@media (max-width: 767.98px)
		@apply border-y border-neutral-200 bg-white
	&::before
		content: ''
		box-shadow: 3px 0px calc(50/1920*100rem) rgba(0, 0, 0, 0.2)
		@apply absolute-center w-[calc(100%+20px)] h-[calc(100%+20px)] bg-white -z-1 opacity-0 pointer-events-none tsn
	*
		@apply tsn
	.caption
		@apply flex flex-col h-full
		@media (max-width: 767.98px)
			@apply flex-1 p-0 h-auto
	.image
		@apply image-ratio pt-[100%] w-full
		@media (max-width: 767.98px)
			flex: 0 0 150px
			@apply size-[150px] pt-0
		@media (max-width: 767.98px)
			flex: 0 0 100px
			@apply size-[100px]
		img
			@apply absolute w-full h-full top-0 left-0
	.name
		@apply body-18 font-bold text-primary-2
		@apply line-clamp-1
		@media (max-width: 767.98px)
			@apply text-[15px]
	.link-arrow
		@media (max-width: 767.98px)
			@apply text-[12px]
	.sub-title
		@apply body-16 text-black/50
		@apply line-clamp-1
		@media (max-width: 767.98px)
			@apply text-[13px]
	.title
		@apply body-16 font-bold text-black
	.desc
		@apply line-clamp-4
		@media (max-width: 767.98px)
			@apply hidden
	.top
		@apply flex-1
		@apply bg-white tsn p-2 border border-neutral-200 xl:px-4
		@media (max-width: 767.98px)
			@apply border-0 flex-none pb-0
	.bot
		@apply bg-white tsn p-2 border border-neutral-200 xl:px-4 xl:pb-5
		@apply flex flex-col
		@media (max-width: 767.98px)
			@apply border-0
		.button
			@apply flex-1 items-end
	.desc
		@apply body-14 font-normal text-black/80
		@apply h-[70px] xl:rem:h-[70px]
	.button
		@apply mt-1 md:mt-3
		a
			@apply uppercase
	// @screen xl

	&:hover
		@apply before:opacity-100 before:pointer-events-auto
		*
			@apply text-white
		.top
			@apply bg-primary-2
			@media (max-width: 767.98px)
				@apply flex-1 pb-2
		.bot
			@apply bg-primary-1
		.sub-title
			@apply text-white/50
		.link-arrow
			@apply text-white
			@apply gap-10

.box-find-doctor-content
	@apply xl:rem:max-w-[1050px] mx-auto

.find-doctor-section
	.doctor-list
		@apply grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 md:gap-10
		& + .button
			.btn-lined
				@apply border-2 border-neutral-200 text-primary-1 uppercase xl:text-xl xl:h-15 xl:rem:min-w-[400px]
				@apply hover:border-current
