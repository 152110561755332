.video-item-2
	&:hover
		.play-btn
			img
				@apply scale-100
				&:nth-of-type(1)
					@apply brightness-0 scale-75
.video-item
	@apply relative
	.image
		@apply aspect-square
		&::before
			@apply font-awesome-sharp content-['\f04b'] text-5xl font-bold text-white absolute-center z-1 size-25 border-4 border-white flex-center rounded-full
			@apply xl:size-30 xl:text-6xl
		&::after
			content: ''
			@apply absolute inset-0 bg-black/[.35]
		.sub-title
			@apply absolute top-1/2 -translate-y-1/2 -right-25 z-1 hidden xl:block border border-neutral-700 bg-white p-5 text-3xl font-bold text-white rem:w-[340px]
			span
				@apply py-8 px-10 bg-primary-2 flex
	&:hover
		.image
			&::after
				@apply bg-transparent
	.caption
		@apply pt-3
	.desc
		@apply text-15px text-black
	.news-date
		@apply flex items-center gap-2 text-black text-sm mb-3
		i
			@apply text-primary-2 text-lg
	.title
		@apply sub-header-20 font-bold text-primary-2 line-clamp-2
	.desc
		@apply text-15px text-neutral-950 line-clamp-3 mt-5

.news-video-list
	@apply grid grid-cols-1 gap-10 md:grid-cols-2 xl:gap-x-5 xl:grid-cols-3

.news-video-section
	.video-item

		&:nth-child(1)
			@apply md:col-span-2 xl:col-span-3
			.image
				@apply md:aspect-[797/339] overflow-visible
			.news-date
				@apply hidden
			.title
				@apply w-max text-primary-1 mx-auto border-b border-[#afafaf] text-center pb-3 px-3 xl:text-4xl
			.desc
				@apply xl:mb-5
			&:hover
				.image
					img
						@apply scale-100
	.btn-lined
		@apply border-2 border-neutral-950 text-primary-1 uppercase xl:text-xl xl:h-15 xl:px-20
		@apply hover:border-current
