.bn-1
	@media (min-width: 1200px)
		outline: rr(10) solid transparent
		@apply transition-all
		&:hover
			outline-color: white
			box-shadow: 0 rr(4) rr(16) 0 rgba(0, 0, 0, 0.6)
			@apply bg-white

	time
		// @apply [&_*]:text-primary-2
	// .des
	// 	@apply flex-auto
	.info
		@apply flex-1 flex flex-col
		@apply mt-0
		.title
			@apply flex-1
	.title
		@apply text-base
	.title,.des
		@apply text-justify
	.des
		@apply flex-none text-15px
		@media (max-width: 767.98px)
			@apply leading-[1rem]
	.btn-long-arrow
		@media (max-width: 767.98px)
			@apply mt-4

.btn-long-arrow
	@apply transition-all
	img
		&:nth-of-type(2)
			@apply hidden
	&:hover
		@apply text-primary-2 gap-10
		img
			&:nth-of-type(1)
				@apply hidden
			&:nth-of-type(2)
				@apply block

.bn-2
	@media (max-width: 767.98px)
		.des
			@apply hidden
		.btn-long-arrow
			@apply mt-4
	.title
		@apply text-base

.bn-1,.bn-2,.bn-3
	.des,.content
		@apply text-justify
	@media (max-width: 767.98px)
		.title
			@apply text-[15px]
		.des
			@apply text-[14px]

.home-news,.our-doctor-section
	@media (max-width: 767.98px)
		.swiper-wrapper
			transform: none !important
			@apply flex-col
		.swiper-slide
			margin-right: 0 !important
			width: 100% !important
			& + .swiper-slide
				@apply mt-2
			&:not(:first-child)
				.bn-1
					@apply flex-row gap-4
					.img
						@apply w-[120px] md:w-[200px] pt-[38px]
					.info
						@apply pt-[10px] mt-0
					.des
						@apply flex-auto
					.btn-long-arrow
						@apply mt-3
				.bn-1,.bn-2,.bn-3
					.title
						@apply text-[12px]
					.des
						@apply text-[14px]

		.swiper-page-button,.swiper-button
			display: none !important
		.swiper-wrapper
			@apply grid-cols-1 gap-y-4
.news-list-1-section-3
	@media (max-width: 767.98px)
		.bn
			&:first-child
				@apply flex-col
				.img
					@apply max-w-full
					a
						@apply pt-[calc(180/266*100%)]
			&:not(:first-child)
				.img
					@apply w-[120px] md:w-[200px] pt-[38px]
					a
						@apply pt-[calc(166/266*100%)]
						@apply h-auto #{!important}
				.info
					@apply pt-[10px] mt-0
				.des
					@apply flex-auto
		.bn-1,.bn-2,.bn-3
			&:not(:first-child)
				.title
					@apply text-[12px]
				.des
					@apply text-[14px]
