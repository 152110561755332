.marquee
	overflow: hidden
	display: flex

.service-item
	@apply h-full flex flex-col
	.content
		@apply flex-1 flex flex-col h-full
		.title
			@apply flex-1
