/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base

/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components

/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities

/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants
