.swiper-pagination
	&.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic
		width: auto !important
		@apply translate-x-0
		.swiper-pagination-bullet
			left: 0 !important
			transform: scale(1)
			display: none
			&.swiper-pagination-bullet-active-prev-prev,&.swiper-pagination-bullet-active-next-next + .swiper-pagination-bullet,&.swiper-pagination-bullet-active-next-next:nth-last-child(2)
				font-size: 0
				@apply pointer-events-none flex
				&:after
					content: '...'
					@apply text-base
			&:first-child,&:last-child
				display: flex !important
				font-size: calc(16/1920*100rem) !important
				@apply pointer-events-auto
			&.swiper-pagination-bullet-active-main,&.swiper-pagination-bullet-active-next,&.swiper-pagination-bullet-active-prev,&:first-child,&:last-child
				@apply text-base pointer-events-auto flex
				&::after
					display: none
			&.swiper-pagination-bullet-active-next-next:nth-last-child(2)
				@apply flex

	.swiper-pagination-bullet
		@apply opacity-100 w-3 h-3 rounded-full bg-secondary-3/50
		&.swiper-pagination-bullet-active
			@apply bg-secondary-3
		&.number
			@apply flex-center size-8 text-base font-normal text-neutral-300 bg-white rounded-none border border-neutral-300
			@apply hover:bg-neutral-100
			&.swiper-pagination-bullet-active
				@apply bg-neutral-100 text-neutral-500
		&.hidden
			display: none
			&.swiper-pagination-bullet-active
				@apply flex
		&.show-more
			font-size: 0
			@apply pointer-events-none
			&:after
				content: '..'
				@apply text-base

	&.is-white
		.swiper-pagination-bullet
			@apply bg-white/50
			&.swiper-pagination-bullet-active
				@apply bg-white

.swiper-button
	> *
		@apply text-2xl text-white w-14 h-14 rounded-full border border-secondary-3 bg-secondary-3 flex-center transition-all cursor-pointer hover:text-neutral-900
		&.swiper-button-disabled
			@apply opacity-40
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-white
		> *
			@apply text-white bg-white/20 border-white hover:text-neutral-200
	&.is-abs
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply -left-12 xl:-left-20
		.button-next
			@apply -right-12 xl:-right-20
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]
	.button-prev,.button-next
		&:hover
			@apply border-primary-1 text-primary-1

.swiper-relative
	@apply lg:px-10 xl:px-0
	.swiper-slide
		@apply h-auto
	&.is-page
		@apply pb-10 lg:pb-0
	.swiper-scrollbar
		@apply h-[4px] bg-neutral-200 rounded-none left-0 w-full
		.swiper-scrollbar-drag
			@apply bg-primary-1 hover:bg-primary-2 rounded-none

.swiper-page-button
	@apply flex-center gap-3 xl:gap-5
	.button-prev, .button-next
		@apply size-8 flex-center text-base border border-neutral-300 text-neutral-300 tsn
		@apply hover:text-primary-1 hover:border-primary-1
	.swiper-pagination
		@apply static flex-center w-max
		&.swiper-pagination-lock
			@apply -mx-3 xl:-mx-5
	.swiper-button-lock
		@apply h-0 overflow-hidden border-0
		@apply -mt-5

.swiper-shadow
	@media (min-width: 1200px)
		.swiper
			@apply p-4 -m-4

.absolute-button
	@screen xl
		@apply absolute right-0 top-0

.arrow-buttons
	.button-prev,.button-next
		@apply absolute top-1/2 -translate-y-1/2
		@apply flex items-center justify-center z-10
		@apply size-[44px] xl:rem:size-[44px]
		&.swiper-button-lock
			@apply opacity-0
		i
			@apply text-[2rem]
	.button-prev
		@apply -left-4 xl:-left-20
	.button-next
		@apply -right-4 xl:-right-20
