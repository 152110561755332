.header-top
	@apply hidden items-start justify-end rem:pb-[3px] rem:pt-[10px] sm:flex
	@media (max-width: 575.98px)
		@apply hidden #{!important}
	> *
		@apply xl:last:mr-12

.header-bot
	@apply md:border-t border-neutral-100 py-2 xl:rem:py-[10px] h-[60px] xl:h-auto
	@apply relative z-30
	@apply flex items-center
	.header-wrap
		@apply flex justify-between rem:max-w-[1432px] rem:px-[15px] mx-auto
		@apply w-full
		.main-menu
			@apply h-full
			>li
				@apply h-full flex items-center
	.header-left
		@apply flex items-center justify-between gap-2
		@screen xl
			width: calc(273/1400*100%)
		@media (max-width: 767.98px)
			@apply h-full
			img
				@apply py-[5px]
	.header-center
		@apply flex-1 hidden xl:block
		@apply rem:my-[-10px]
	.header-right
		@apply flex items-center justify-end gap-3
		@media (max-width: 767.98px)
			@apply w-full
			@apply relative

header
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08)
	@apply fixed xl:relative bg-white top-0 left-0 w-full z-999
	@media (max-width: 767.98px)
		background-color: rgba(153,163,166,.5)
		&::after
			content: ''
			background-image: url(../img/mobile-nav.jpg)
			@apply absolute inset-0 bg-no-repeat bg-cover -z-1
			@apply pointer-events-none
			@apply transition-all
			@apply bg-center
			// @apply translate-y-3 opacity-0
		// &.active
		// 	&::after
		// 		@apply translate-y-0 opacity-100 delay-150
	.menu-top, .button-email
		a
			@apply block rem:pb-[11px] body-14 font-normal text-neutral-700
			@apply hover:text-primary-2 hover-underline
		li
			&.active
				a
					@apply text-primary-2 hover-underline-active
	.button-115
		a
			@apply h-[32px] rem:px-[10px] flex-center rounded-1 body-14 font-normal text-white bg-primary-1 hover:bg-primary-2 xl:h-6
			.icon
				@apply md:hidden
				~ *
					@apply hidden md:block
	.button-phone, .button-login
		a
			@apply body-14 font-bold text-neutral-700 uppercase flex-center rem:gap-[9px] rem:pb-[11px]
			@apply hover:text-primary-2 hover-underline
			@media (max-width: 767.98px)
				@apply text-white
	.button-email, .button-115
		@screen md
			@apply rem:ml-[25px]
	.button-phone
		@apply ml-15
	#buttonMenu
		@media (max-width: 767.98px)
			@apply absolute left-0 top-1/2 -translate-y-1/2
	.button-login
		@apply rem:ml-[23px]
	.logo
		@apply h-10 md:h-12 xl:h-15
		@media (max-width: 767.98px)
			@apply absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-2
		a
			@apply h-full
		img
			@apply w-auto max-h-none
	.second-logo
		@apply h-[60px] xl:rem:h-[60px]
		@apply flex
		a
			@apply h-full
		&:before
			content: ''
			@apply static
			@apply border-l border-l-neutral-200 pr-1 sm:pr-3
		img
			@apply w-auto max-h-none h-full object-contain
	.main-menu
		@apply flex-center gap-5
		.dropdown
			@apply absolute top-full left-0 z-10
			@apply bg-white w-full py-10 border-t border-neutral-950
			@apply transition-all duration-300
			@apply opacity-0 pointer-events-none
		.dropdown-wrapper
			max-width: rr(1400)
			@apply w-full mx-auto
			>ul
				@apply grid grid-cols-3 gap-10
				a
					@apply flex flex-col
					@apply text-left items-start
					@apply body-18 font-bold text-primary-2
					.img
						+media-ratio(274/440)
						@apply relative h-0 overflow-hidden w-full block
						img
							@apply w-full h-full object-cover absolute top-0 left-0
		a
			@apply body-14 font-bold text-neutral-700 uppercase flex-center rem:pb-[11px] rem:mt-[11px] gap-2
			@apply hover:text-primary-2 hover-underline
		.toggle-icon
			@apply hidden
		li
			&.active
				>a
					@apply text-primary-2 hover-underline-active
			&[class*='has-children']
				>a
					// .toggle-icon
					// 	svg
					// 		@apply size-4
				&:hover
					>ul
						@apply opacity-100 pointer-events-auto
					>.dropdown
						@apply opacity-100 pointer-events-auto
				>ul
					min-width: 220px
					@apply absolute top-full left-0 bg-white border-none space-y-1 z-1
					@apply shadow-drop-shadow-light
					@apply p-3
					@apply opacity-0 transition-all duration-300 pointer-events-none
					a
						@apply py-2 justify-start
	.button-search
		@apply flex-center size-[32px] border border-primary-2 text-primary-2 text-[14px] cursor-pointer tsn xl:rem:size-[26px] xl:text-sm
		@screen xl
			@apply hover:bg-primary-2 hover:text-white
		@media (max-width: 767.98px)
			@apply bg-transparent border-white text-white

.mobile-wrap
	@apply fixed top-[60px] sm:top-[101px] w-screen bg-white shadow-drop-shadow-light z-[1001] pt-[14px] pl-[16px] pb-[16px] max-w-[390px] -left-full opacity-0 tsn pointer-events-none hidden xl:hidden
	@apply overflow-auto
	@apply h-[calc(100vh-60px)] sm:h-[calc(100vh-101px)]
	.navbar-nav-list
		@apply pr-[16px] flex flex-col
	.toggle-submenu
		@apply absolute right-0 z-1 flex justify-end items-center size-[60px] top-0
		&::before
			@apply font-awesome content-['\f054'] text-[16px] font-light text-primary-2 tsn
		&.active
			&::before
				@apply rotate-90
	.button-search
		@apply hover:text-primary-2 hover-underline
		@apply h-[60px] flex items-center
		i
			@apply text-[18px] text-primary-2 py-4
			@media (max-width: 767.98px)
				@apply size-10 border border-primary-2 flex items-center justify-center
	.main-menu, .menu-top
		@apply uppercase
		li
			a
				@apply block
			&.active
				> a
					@apply font-bold underline #{!important}
		> li
			@apply border-b border-primary-2/20 relative
			> a
				@apply inline-block text-[18px] leading-normal font-normal text-primary-2 uppercase
				@apply py-[16px] w-full
			> .sub-menu
				@apply p-3 hidden
				@apply border-t border-t-primary-2/50
			&[class*='has-children']
				>a
					@apply flex items-center justify-between
			.dropdown
				@apply hidden rounded-none
				@apply p-3 border-t border-t-primary-2/50
				ul
					.img
						@apply hidden
					a
						@apply text-15px py-3 text-primary-2
					li
						&.active
							a
								@apply text-primary-2
			.toggle-icon
				@apply size-10 -my-2 relative z-1
				svg
					@apply w-full h-full
	.sub-menu
		> li
			> a
				@apply text-[16px] font-medium text-primary-2
				@apply py-3
	.sub-menu,.dropdown-wrapper >ul
		> li
			> a
				@apply flex items-center gap-2
				&::before
					content: ''
					@apply static
					@apply border-t-[6px] border-l-[8px] border-b-[6px]
					@apply border-l-primary-2 bg-transparent border-t-transparent border-b-transparent
	.button-email, .button-phone, .button-login
		@apply border-b border-primary-2/20 relative
		a
			@apply flex items-center gap-2 py-[16px] text-[18px] font-normal text-primary-2 uppercase
	&.active
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-32 left-0 right-0 w-full z-[1005] hidden px-10 xl:rem:top-[160px]
	.close-search
		@apply xl:hidden
	@media (max-width: 1279.98px)
		@apply top-[60px] sm:top-[101px] h-[calc(100vh-60px)] sm:h-[calc(100vh-101px)]
		@apply bg-white
		.close-search
			@apply absolute top-0 right-0 size-[4rem] flex items-center justify-center
			i
				@apply text-[3rem] font-bold text-neutral-800
		.searchbox
			@apply flex gap-3 w-full items-center h-full
			input
				box-shadow: none
				@apply bg-white text-[20px] sm:text-[1.7rem] md:text-[2.5rem] outline-0 border-transparent outline-transparent focus:outline-0 focus:border-0 focus:border-b-[3px] p-0 rounded-none
				@apply border-0 border-b-[3px] border-b-primary-2
				@apply h-[4rem]
		.searchbutton
			flex: 0 0 4rem
			@apply size-[4rem] text-[2.5rem] static transform-none
			@apply border-[3px] border-primary-2

.language_bar_list
	a
		@apply size-[25px] rounded-full block overflow-hidden
		@apply border border-primary-2
		img
			width: 100% !important
			height: 100% !important
			@apply object-cover block
.wpml-ls
	&.wpml-ls-legacy-dropdown-click
		@apply w-full relative
		> ul
			> li
				@apply flex-center
				img
					@apply size-5 rounded-full w-full h-full object-cover
				> a
					@apply flex-center text-base font-normal text-neutral-500 bg-none bg-transparent p-0 border-0
					&::after
						@apply font-awesome content-['\f078'] text-xs font-light text-current ml-1 border-none flex static
						@media (max-width: 767.98px)
							@apply text-white
				> .wpml-ls-sub-menu
					@apply absolute top-full left-0 bg-white p-1 hidden border-none space-y-1
					li
						@apply flex-center
