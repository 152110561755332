.box-career-info
	@apply flex flex-col gap-10 bg-white border border-neutral-200 p-4 md:px-10 md:justify-between md:flex-row md:items-center xl:px-20
	.button
		@apply md:flex-col md:gap-1
		a
			@apply normal-case

.box-career-detail
	@apply flex flex-col gap-10 xl:flex-row xl:justify-between
	.left
		@apply xl:flex-1
	.right
		@apply xl:rem:w-[250px]
		ul
			@apply border border-primary-2 p-5 space-y-3 xl:sticky xl:top-30
		li
			@apply flex items-center gap-2
		.icon
			@apply size-10 flex-center
			img
				@apply w-full h-full object-contain
		.caption
			@apply flex-1

.career-detail-section
	@apply pt-5 pb-10 md:pt-0
	.box-career-info
		@apply md:-mt-20
	.full-content
		@apply xl:px-3
		h3
			@apply text-xl font-bold text-primary-2 uppercase

.contact-need-item
	@apply bg-current p-4 text-center text-primary-1/80 rem:mt-[30px] xl:p-10 xl:mt-10
	.icon
		@apply size-15 rounded-full mx-auto rem:-mt-[46px] bg-primary-1/80 flex-center mb-5 xl:size-20 xl:-mt-20
	a
		@apply hover-underline inline-block

.contact-need-list
	@apply grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:rem:gap-[34px]
	.contact-need-item
		&:nth-child(n+2)
			@apply bg-primary-2/80
			.icon
				@apply bg-primary-2/80
