@font-face
    font-family: 'UTM Avo'
    src: url('../fonts/UTMAvo.eot')
    src: url('../fonts/UTMAvo.eot?#iefix') format('embedded-opentype'), url('../fonts/UTMAvo.woff2') format('woff2'), url('../fonts/UTMAvo.woff') format('woff'), url('../fonts/UTMAvo.ttf') format('truetype'), url('../fonts/UTMAvo.svg#UTMAvo') format('svg')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'UTM Avo'
    src: url('../fonts/UTMAvo-BoldItalic.eot')
    src: url('../fonts/UTMAvo-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/UTMAvo-BoldItalic.woff2') format('woff2'), url('../fonts/UTMAvo-BoldItalic.woff') format('woff'), url('../fonts/UTMAvo-BoldItalic.ttf') format('truetype'), url('../fonts/UTMAvo-BoldItalic.svg#UTMAvo-BoldItalic') format('svg')
    font-weight: bold
    font-style: italic
    font-display: swap

@font-face
    font-family: 'UTM Avo'
    src: url('../fonts/UTMAvoBold.eot')
    src: url('../fonts/UTMAvoBold.eot?#iefix') format('embedded-opentype'), url('../fonts/UTMAvoBold.woff2') format('woff2'), url('../fonts/UTMAvoBold.woff') format('woff'), url('../fonts/UTMAvoBold.ttf') format('truetype'), url('../fonts/UTMAvoBold.svg#UTMAvoBold') format('svg')
    font-weight: bold
    font-style: normal
    font-display: swap

@font-face
    font-family: 'UTM Avo'
    src: url('../fonts/UTMAvo-Italic.eot')
    src: url('../fonts/UTMAvo-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/UTMAvo-Italic.woff2') format('woff2'), url('../fonts/UTMAvo-Italic.woff') format('woff'), url('../fonts/UTMAvo-Italic.ttf') format('truetype'), url('../fonts/UTMAvo-Italic.svg#UTMAvo-Italic') format('svg')
    font-weight: normal
    font-style: italic
    font-display: swap

