.services-detail
	table
		@apply w-full
		th
			@apply bg-primary-2
			@apply py-2
			@apply rem:px-5
			@apply sm:text-base text-sm font-bold text-white
			&:not(:first-child)
				@apply text-left
		td
			@apply sm:text-15px text-[12px] text-neutral-700 font-normal
			@apply py-2
			@apply rem:px-[10px]
			&:first-child
				@apply text-center
			&:not(:first-child)
				@apply text-left
			&:nth-of-type(3)
				@apply text-center
		tr
			& ~ tr
				&:nth-of-type(odd)
					@apply bg-neutral-50

		th,td
			&:first-child
				@apply rem:w-[46px]
			&:not(:first-child)
				@apply border-l border-l-neutral-200
		td
			&:nth-of-type(2)
				width: calc(590/1190*100%)
			&:nth-of-type(3)
				width: calc(554/1190*100%)
	select
		@apply text-left
		@apply pl-3
	[class*='section-header-32']
		@media (max-width: 767.98px)
			@apply text-[17px]
	.des
		@apply text-gray-800
	.select-category-detail
		.option
			@apply font-bold uppercase
.customer-services-detail
	@media (max-width: 767.98px)
		@apply text-[14px] #{!important}
