.faq-item
	@apply flex gap-2 xl:gap-5
	.icon
		@apply size-15 bg-white rounded-full flex-center xl:size-20
	.caption
		@apply flex-1 text-15px font-normal text-white
	.title
		@apply sub-header-20 font-bold text-white

.box-faq
	@apply bg-primary-2 p-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:py-10

.accordion-item
	@apply px-3 bg-white border-2 border-gray-200
	.accordion-head
		@apply flex items-center justify-between gap-3 py-3 cursor-pointer
	.accordion-title
		@apply text-15px font-bold text-black
	.accordion-icon
		@apply size-8 flex-center text-lg text-primary-2
	.accordion-content
		@apply body-14 font-normal text-black pb-3 hidden
	&.active
		.accordion-icon
			&::before
				@apply content-['\f068']
