.header-64
	@apply text-[32px] leading-[calc(65/69)] xl:text-64

.header-48
	@apply text-[28px] md:text-[30px] leading-snug xl:text-5xl

.header-40
	@apply text-[23px] md:text-[26px] leading-snug xl:text-40

.section-header-32
	@apply text-[20px] md:text-[24px] xl:text-32
.section-header-30
	@apply text-[20px] md:text-[24px] xl:rem:text-[30px]

.sub-header-24
	@apply text-[20px] leading-snug xl:text-2xl

.sub-header-20
	@apply text-[18px] leading-snug xl:text-xl

.body-18
	@apply text-[16px] xl:text-lg

.body-16
	@apply text-[16px] xl:text-base

.body-14
	@apply text-[15px] md:text-[14px] xl:text-sm

.label-12
	@apply text-[12px] leading-snug xl:text-xs

.site-title
	@apply section-header-32

.site-category
	@apply sub-header-20 font-normal text-primary-2
	@media (max-width: 767.98px)
		@apply text-[16px]
	&.is-white
		@apply text-white

.full-content
	--tw-prose-bold: #545454 !important
	@apply prose max-w-none
	@apply rem:text-[15px] font-normal text-gray-900
	@apply prose-spacing-3
	@media (max-width: 767.98px)
		@apply text-[14px]
	> *
		@apply first:mt-0 last:mb-0
	p
		img
			@apply m-0
	ul
		@apply list-disc pl-4
	ol
		@apply list-decimal pl-4
	img
		width: 100% !important
		height: auto !important

@media (max-width: 767.98px)
	[class*='text-15px']
		@apply text-[14px]
