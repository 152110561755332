.dropdown
	@apply relative
	.dropdown-toggle
		@apply relative px-4 py-2 sub-header-20 font-bold text-primary-2 uppercase cursor-pointer h-12 border border-primary-2
		&::after
			@apply font-awesome content-['\f078'] font-light text-xl text-current ml-3
		&.active
			&::after
				@apply content-['\f077']
	.dropdown-menu
		@apply absolute top-full left-0 w-full min-w-max z-10 bg-white px-5 py-3 space-y-2 shadow hidden
		a
			@apply text-15px text-neutral-950 py-2 block hover:text-primary-2
