.button
	@apply flex flex-wrap gap-5

.link
	@apply flex-center body-16 font-normal text-primary-2 tsn
	@apply hover:text-primary-2 hover-underline
	&.is-white
		@apply text-white hover:text-white

.link-arrow
	@apply flex-center body-14 font-bold text-primary-1 gap-3 tsn xl:gap-5
	@apply hover:text-primary-2 items-center
	&.is-white
		@apply text-white hover:text-white

.btn-solid
	@apply flex-center bg-primary-1 border border-primary-1 text-sm font-bold text-white text-center  uppercase tsn
	@apply hover:bg-white hover:text-primary-1 hover:border-current
	@apply px-5 py-[10px] lg:py-2 xl:px-10
	&.is-green
		@apply bg-primary-2 border-primary-2 hover:text-primary-1 hover:bg-white hover:border-current

.btn-lined
	@apply flex-center bg-white border border-current text-sm font-bold text-primary-1 text-center px-5 py-1 uppercase tsn
	@apply hover:text-primary-2 hover:border-current
	@apply px-5 py-[10px] lg:py-2 xl:px-10 flex gap-4
	&.is-green
		@apply text-primary-2 hover:text-primary-1
