.news-latest-item
	@apply relative flex gap-4
	.image
		@apply aspect-square w-20
	.caption
		@apply flex-1
	.news-date
		@apply text-neutral-300 mb-1
	.title
		@apply text-15px font-bold text-black/80
	&:hover
		.title
			@apply text-primary-1

.news-detail-image
	// @apply aspect-[532/231]
	// @apply aspect-[750/538]
	img
		@apply h-auto

.action-list
	@apply flex items-center gap-2 xl:gap-5
	a
		@apply flex-center gap-1
		&.heart
			@apply hover:text-primary-1
			&.active
				@apply text-primary-1
				i
					&::before
						@apply font-bold

.interact-list
	@apply flex items-center gap-4 xl:gap-5
	a
		@apply flex items-center gap-2 text-15px text-neutral-500 hover:text-primary-2
		&.active
			@apply text-primary-1
			i
				&::before
					@apply font-bold

.author-list
	@apply flex items-center gap-2 xl:gap-5
	li
		@apply body-14

// .next-link
// 	@apply py-3 my-4 border-y border-neutral-300 flex items-center justify-end gap-2 text-right xl:gap-3
// 	.icon
// 		@apply size-7 flex-center border border-neutral-300 text-neutral-300 text-lg
// 	.caption
// 		@apply body-14 text-neutral-500
// 	.title
// 		@apply body-16 text-neutral-300

.comment-form-item
	@apply flex gap-2 xl:gap-5
	.comment-avatar
		@apply size-15 overflow-hidden xl:size-20
		img
			@apply size-full object-cover
	.comment-form
		@apply flex-1
		textarea
			@apply px-2
		button
			@apply ml-auto mr-0

.comment-item
	@apply flex gap-2 xl:gap-5
	.comment-avatar
		@apply size-15 overflow-hidden xl:size-20
		img
			@apply size-full object-cover
	.comment-content
		@apply flex-1
	.comment-body
		@apply body-14 text-black
	.comment-footer
		@apply flex items-center gap-4
		a
			@apply body-14 text-neutral-500 hover:text-primary-1
			&.active
				@apply text-primary-1
				i
					&::before
						@apply font-bold
	.comment-reply
		.comment-avatar
			@apply size-14
	.comment-form-item
		.comment-form
			textarea
				@apply h-14
			button
				@apply xl:px-5 xl:h-8 xl:text-xs

.box-comment
	> .button
		a
			@apply w-full bg-neutral-100 text-neutral-700 border-neutral-100 xl:h-12 xl:text-lg
			@apply hover:bg-neutral-200 hover:border-neutral-200

.box-news-detail-search
	.searchbox
		input
			@apply w-full h-12 border-neutral-50 rounded-none pl-5 pr-12 text-base text-neutral-700
			@apply placeholder:text-black/30 placeholder:normal-case
		button
			@apply absolute top-1/2 -translate-y-1/2 right-2 z-1 size-8 border border-black/20 flex-center text-base text-black/20
			@apply hover:bg-primary-2 hover:text-white hover:border-primary-2

.box-latest-news
	.tabslet-tabs
		@apply border border-neutral-50 p-3 flex items-center gap-2 justify-between
		a
			@apply block pb-1 text-center text-sm font-normal text-black/50 border-b border-transparent hover:border-primary-2
		li
			&.active
				a
					@apply border-primary-2 text-black font-bold
	.button
		a
			@apply w-full

.news-detail-section
	.swiper-pagination
		@apply flex-center gap-2 pointer-events-none xl:bottom-5
		.swiper-pagination-bullet
			@apply size-3 rounded-full text-0 pointer-events-auto bg-primary-2 border-none
			&.swiper-pagination-bullet-active
				@apply bg-primary-1
	.button-prev
		@apply absolute left-0 bottom-0 z-1 size-8 flex-center text-white text-2xl xl:size-13 xl:text-3xl
	.button-next
		@apply absolute right-0 bottom-0 z-1 size-8 flex-center text-white text-2xl xl:size-13 xl:text-3xl
	.date-action
		@apply flex items-center justify-between pb-1 border-b border-neutral-300
		.news-date
			@apply flex items-center gap-2 text-primary-2 text-sm
			i
				@apply text-lg
	.box-comment
		@apply mt-10
	.post-navigation
		@apply py-3 border-y border-neutral-300 flex items-center justify-end gap-2 text-right xl:gap-3
		.icon
			@apply size-7 text-base
		.caption
			@apply text-neutral-500
		.title
			@apply text-neutral-300
		.link
			&:hover
				.title
					@apply text-primary-2

.box-news-detail-search
	@apply hidden
