.contact-form
	.form-group
		input
			@apply h-12
		textarea
			@apply xl:h-30
		input, textarea
			@apply bg-[#ebebeb]
			&:focus
				@apply bg-white
			&:valid
				@apply outline-none ring-2 ring-secondary-3 ring-offset-0
				@apply bg-white
		button[type="submit"]
			@apply ml-auto mr-0
		label
			@apply body-16 font-bold text-neutral-300

.rating-wrap
	.form-group
		textarea
			@apply bg-neutral-200
			&:focus
				@apply bg-white
			&:valid
				@apply outline-none ring-2 ring-secondary-3 ring-offset-0
				@apply bg-white
		button[type="submit"]
			@apply ml-auto mr-0
		&.form-rating
			@apply flex items-center justify-between
	@screen xl
		.row
			@apply -mx-15
			> *
				@apply px-15

.contact-maps
	@apply aspect-[1369/446]

.contact-section
	.block-content
		@apply text-[#757575]
		[class*='sub-header']
			@media (max-width: 767.98px)
				@apply text-[16px] leading-[1.3]
