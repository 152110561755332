.news-date
	@apply text-[10px] font-normal text-neutral-300

.news-category
	@apply body-18 font-normal text-neutral-300 uppercase

.news-item
	@apply relative flex flex-col tsn md:flex-row-reverse md:even:flex-row
	.image
		@apply aspect-[260/226] md:w-1/2
	.caption
		@apply flex-1 bg-primary-2 p-4 text-white flex flex-col justify-center xl:p-10
		*
			@apply text-white
	.title
		@apply sub-header-24 font-bold uppercase
	.line
		@apply mt-7 mb-3 border-t-2 border-neutral-300 w-[52%]
	.news-date
		@apply text-15px italic
	.desc
		@apply text-15px mt-3
	&:hover
		box-shadow: 0 0 43px rgba(0, 0, 0, 0.57)
	&.is-video
		.image
			@apply w-full bg-neutral-800
			img
				@apply opacity-50
			&::before
				@apply font-awesome-sharp content-['\f04b'] text-5xl font-bold text-white absolute-center z-1 size-25 border-4 border-white flex-center rounded-full
				@apply xl:size-30 xl:text-6xl
		.caption
			@apply hidden
		&:hover
			.image
				img
					@apply opacity-100
	&.is-event
		.caption
			@apply bg-primary-1

.news-list-2-wrap
	@apply grid grid-cols-1 gap-8 md:grid-cols-3 xl:gap-x-10
	.news-item
		@screen md
			@apply col-span-3
			&:nth-child(2), &:nth-child(5)
				@apply col-span-1
			&:nth-child(3), &:nth-child(4)
				@apply col-span-2
			&:nth-child(5)
				.image
					@apply hidden
		@screen xl
			&:nth-child(1), &:nth-child(6), &:nth-child(7)
				.image
					@apply w-[calc(465/800*100%)] aspect-[465/300]
				.caption
					@apply xl:p-12
				.title
					@apply section-header-32
				.news-date
					@apply body-18

.news-list-1-section-1
	.column-2
		.bn-1
			.btn-long-arrow
				@apply hidden
			.time-comment
				@apply mt-3
			.des
				@screen xl
					@apply mt-5
	.column-3
		.bn-1
			.btn-long-arrow
				@apply hidden
			.des
				@apply hidden

.news-list-1-section-3
	.img
		@apply text-center
