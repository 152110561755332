.testimonial-top
	@apply text-center relative xl:rem:max-w-[650px] xl:ml-7
	.content
		@apply section-header-32 font-normal text-black italic mb-2
	.author
		@apply section-header-32 font-bold text-primary-2
	.sub-title
		@apply sub-header-20 font-normal text-primary-2
	@screen xl
		&::before
			content: url(../img/quote-left.png)
			@apply absolute -left-7 top-0
		.content
			&::after
				content: url(../img/quote-right.png)
				@apply ml-auto mr-3 block text-right

.testimonial-item
	@apply flex gap-2 p-4 border border-neutral-400/30 xl:gap-5 xl:px-8 xl:pb-8
	.avatar
		@apply size-12 xl:size-15
	.caption
		@apply flex-1
	.content
		@apply font-normal text-neutral-700/70 mb-5
		@apply text-15px
		p
			&:first-child
				&::before
					content: url(../img/quote.png)
					@apply mr-3
	.author
		@apply text-15px font-bold text-primary-2
	.sub-title
		@apply body-14 font-normal text-neutral-950/70
		@media (max-width: 767.98px)
			@apply hidden

.testimonial-list
	@apply grid grid-cols-1 gap-5 md:grid-cols-2 xl:gap-8
