.banner-child
	.image
		// @apply aspect-[1350/900] md:aspect-[1920/600]
		@apply aspect-[1920/600]
		@media (max-width: 767.98px)
			height: 170px
			@apply aspect-auto
	.global-breadcrumb
		@apply absolute top-0 left-0 w-full z-1 bg-transparent bg-none
		@media (max-width: 767.98px)
			@apply mb-0
	.swiper-pagination
		@apply xl:bottom-8
		.swiper-pagination-bullet
			@apply bg-white xl:size-4 xl:mx-3
			&.swiper-pagination-bullet-active
				@apply bg-primary-2
	.caption
		@apply absolute-center z-1 w-full
	&.banner-testimonial
		.caption
			@apply static translate-x-0 translate-y-0 py-3
			@screen xl
				@apply absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2
	.header-48
		@media (max-width: 767.98px)
			@apply text-[20px]
