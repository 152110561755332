.searchbox
	@apply relative container px-0
	input
		@apply w-full h-12 border-neutral-200 border rounded-1 pl-5 pr-12 text-base text-neutral-700 placeholder:text-neutral-200 focus:outline-none focus:border-primary-2
	button
		@apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-secondary-3 tsn

.search-result-item
	@apply relative
	.image
		@apply aspect-[345/189]
	.caption
		@apply pt-3 xl:pt-5
	.title
		@apply section-header-32 font-bold text-primary-2

.search-result
	@apply grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-10 xl:gap-x-20 xl:gap-y-12

.search-section
	.searchbox
		@apply flex justify-between gap-3 xl:gap-10
		.searchinput
			@apply text-black/50 px-0 border-x-0 border-t-0 rounded-none border-b-2 border-neutral-300 flex-1 xl:h-20 text-xl
			@apply focus:ring-0
		.searchbutton
			@apply static flex-center border-4 border-neutral-300 text-neutral-300 translate-x-0 translate-y-0 xl:size-12 xl:text-3xl
			@apply hover:bg-neutral-300 hover:text-white
