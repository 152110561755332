.global-breadcrumb
	@apply bg-white
	&:not(.breadcrumb-doctor)
		@media (max-width: 767.98px)
			@apply mb-[-16px]
	.breadcrumb
		@apply flex flex-wrap items-center py-3 gap-x-2 gap-y-2 xl:py-5
		a
			@apply block text-sm leading-normal font-bold text-primary-2 uppercase
			&.active
				@apply text-primary-1
		li
			@apply flex items-center last:after:hidden
			&::after
				content: '/'
				@apply pl-2 text-primary-2 text-base leading-none
			// &:first-child
			// 	a
			// 		@apply text-0
			// 		span
			// 			@apply hidden
			// 		&::before
			// 			@apply font-awesome content-['\f015'] text-primary-2 text-base font-bold leading-none flex-center

	// .rank-math-breadcrumb
	// 	@apply py-2 xl:rem:py-[10px]
	// 	p
	// 		@apply flex flex-wrap items-center gap-2 xl:gap-4
	// 		> *
	// 			@apply body-16 font-normal text-neutral-400
	// 			&:first-child
	// 				@apply text-0
	// 				&::before
	// 					@apply font-awesome content-['\e487'] text-neutral-400 text-base font-thin leading-none flex-center
	// 		a
	// 			@apply hover:text-primary-2
	// 	.separator
	// 		@apply text-0
	// 		&::after
	// 			content: '|'
	// 			@apply text-base font-normal text-neutral-400 leading-none
