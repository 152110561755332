.services-list
	.col-left
		@media (max-width: 1023.98px)
			@apply order-2
	.col-right
		@media (max-width: 1023.98px)
			@apply order-1
	[class*='section-header-32']
		@media (max-width: 767.98px)
			@apply text-[25px]
	.item
		.des
			@apply mt-2
