@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

@layer base {
  html, body {
    font-size: 16px; }
  @screen xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply accent-blue-700 {}    @apply font-sans overflow-y-overlay text-main {}    @apply text-15px leading-[1.3] {} }
  main {
    @apply pt-[60px] sm:pt-30 {}    @apply xl:pt-0 {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  ::is(tbody, table) > tr:nth-child(odd) {
    background: #0001; }
  @media (prefers-reduced-motion) {
    *, *::before, *::after {
      animation-duration: 0s !important;
      /* additional recommendation */
      transition: none !important;
      scroll-behavior: auto !important; } } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[96vw] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1220/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain {} }
  .img-zoom {
    @apply relative overflow-hidden {} }
    .img-zoom img {
      @apply w-full h-full object-cover transition-all duration-300 ease-linear hover:scale-105 {} }
  .item-hover .image {
    @apply relative overflow-hidden {} }
    .item-hover .image img {
      @apply transition-all {} }
  .item-hover:hover .image img {
    @apply scale-110 {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-10 xl:py-15 {} }
  .section-t {
    @apply pt-10 xl:pt-15 {} }
  .section-b {
    @apply pb-10 xl:pb-15 {} }
  .section-small {
    @apply py-8 xl:py-10 {} }
  .section-t-small {
    @apply pt-8 xl:pt-10 {} }
  .section-b-small {
    @apply pb-8 xl:pb-10 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .section-t-large {
    @apply pt-15 xl:pt-20 {} }
  .section-b-large {
    @apply pb-15 xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-4 {} }
    .row > * {
      @apply px-4 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in img {
    @apply transition-all {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms;
    @apply relative {} }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .text-xs {
    @apply text-[14px] xl:text-[calc(12/1920*100rem)] {} }
  .tsn {
    @apply transition-all duration-300 ease-in-out {} }
  .link-all {
    @apply absolute inset-0 z-2 {} }
  .icon-long-arrow {
    @apply flex-center text-current tsn {} }
    .icon-long-arrow::before {
      content: '';
      @apply w-5 h-[2px] bg-current {} }
    .icon-long-arrow::after {
      content: '';
      @apply size-0 border-y-[3px] border-y-transparent border-l-[5px] border-l-current {} }
  .image-fit img {
    @apply absolute inset-0 w-full h-full object-cover {} }
  .image-contain img {
    @apply absolute inset-0 w-full h-full object-contain {} }
  .image-ratio {
    @apply relative w-full h-0 overflow-hidden block {} }
    .image-ratio img {
      @apply absolute inset-0 w-full h-full object-cover {} }
  .play-btn {
    @apply w-20 xl:rem:w-[100px] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 transition-all duration-500 {}    @apply rounded-full {} }
    .play-btn img {
      @apply transition-all {} }
      .play-btn img:nth-of-type(2) {
        @apply opacity-0 {} }
  @screen xl {
    .play-btn:hover img:nth-of-type(1) {
      @apply brightness-0 scale-75 {} } }
    .play-btn.play-video {
      @apply bg-primary-1 scale-70 opacity-70 {} }
      .play-btn.play-video img:nth-of-type(1) {
        @apply opacity-0 {} }
      .play-btn.play-video img:nth-of-type(2) {
        @apply opacity-100 {} } }

@media (max-width: 767.98px) {
  [has-expand-btn] .expand-item {
    @apply hidden {} }
  [has-expand-btn] .expand-btn {
    @apply mt-3 {} } }

@font-face {
  font-family: 'UTM Avo';
  src: url("../fonts/UTMAvo.eot");
  src: url("../fonts/UTMAvo.eot?#iefix") format("embedded-opentype"), url("../fonts/UTMAvo.woff2") format("woff2"), url("../fonts/UTMAvo.woff") format("woff"), url("../fonts/UTMAvo.ttf") format("truetype"), url("../fonts/UTMAvo.svg#UTMAvo") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'UTM Avo';
  src: url("../fonts/UTMAvo-BoldItalic.eot");
  src: url("../fonts/UTMAvo-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/UTMAvo-BoldItalic.woff2") format("woff2"), url("../fonts/UTMAvo-BoldItalic.woff") format("woff"), url("../fonts/UTMAvo-BoldItalic.ttf") format("truetype"), url("../fonts/UTMAvo-BoldItalic.svg#UTMAvo-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'UTM Avo';
  src: url("../fonts/UTMAvoBold.eot");
  src: url("../fonts/UTMAvoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/UTMAvoBold.woff2") format("woff2"), url("../fonts/UTMAvoBold.woff") format("woff"), url("../fonts/UTMAvoBold.ttf") format("truetype"), url("../fonts/UTMAvoBold.svg#UTMAvoBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'UTM Avo';
  src: url("../fonts/UTMAvo-Italic.eot");
  src: url("../fonts/UTMAvo-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/UTMAvo-Italic.woff2") format("woff2"), url("../fonts/UTMAvo-Italic.woff") format("woff"), url("../fonts/UTMAvo-Italic.ttf") format("truetype"), url("../fonts/UTMAvo-Italic.svg#UTMAvo-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

.header-64 {
  @apply text-[32px] leading-[calc(65/69)] xl:text-64 {} }

.header-48 {
  @apply text-[28px] md:text-[30px] leading-snug xl:text-5xl {} }

.header-40 {
  @apply text-[23px] md:text-[26px] leading-snug xl:text-40 {} }

.section-header-32 {
  @apply text-[20px] md:text-[24px] xl:text-32 {} }

.section-header-30 {
  @apply text-[20px] md:text-[24px] xl:rem:text-[30px] {} }

.sub-header-24 {
  @apply text-[20px] leading-snug xl:text-2xl {} }

.sub-header-20 {
  @apply text-[18px] leading-snug xl:text-xl {} }

.body-18 {
  @apply text-[16px] xl:text-lg {} }

.body-16 {
  @apply text-[16px] xl:text-base {} }

.body-14 {
  @apply text-[15px] md:text-[14px] xl:text-sm {} }

.label-12 {
  @apply text-[12px] leading-snug xl:text-xs {} }

.site-title {
  @apply section-header-32 {} }

.site-category {
  @apply sub-header-20 font-normal text-primary-2 {} }
  @media (max-width: 767.98px) {
    .site-category {
      @apply text-[16px] {} } }
  .site-category.is-white {
    @apply text-white {} }

.full-content {
  --tw-prose-bold: #545454 !important;
  @apply prose max-w-none {}  @apply rem:text-[15px] font-normal text-gray-900 {}  @apply prose-spacing-3 {} }
  @media (max-width: 767.98px) {
    .full-content {
      @apply text-[14px] {} } }
  .full-content > * {
    @apply first:mt-0 last:mb-0 {} }
  .full-content p img {
    @apply m-0 {} }
  .full-content ul {
    @apply list-disc pl-4 {} }
  .full-content ol {
    @apply list-decimal pl-4 {} }
  .full-content img {
    width: 100% !important;
    height: auto !important; }

@media (max-width: 767.98px) {
  [class*='text-15px'] {
    @apply text-[14px] {} } }

@keyframes ctaPhoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

.button {
  @apply flex flex-wrap gap-5 {} }

.link {
  @apply flex-center body-16 font-normal text-primary-2 tsn {}  @apply hover:text-primary-2 hover-underline {} }
  .link.is-white {
    @apply text-white hover:text-white {} }

.link-arrow {
  @apply flex-center body-14 font-bold text-primary-1 gap-3 tsn xl:gap-5 {}  @apply hover:text-primary-2 items-center {} }
  .link-arrow.is-white {
    @apply text-white hover:text-white {} }

.btn-solid {
  @apply flex-center bg-primary-1 border border-primary-1 text-sm font-bold text-white text-center  uppercase tsn {}  @apply hover:bg-white hover:text-primary-1 hover:border-current {}  @apply px-5 py-[10px] lg:py-2 xl:px-10 {} }
  .btn-solid.is-green {
    @apply bg-primary-2 border-primary-2 hover:text-primary-1 hover:bg-white hover:border-current {} }

.btn-lined {
  @apply flex-center bg-white border border-current text-sm font-bold text-primary-1 text-center px-5 py-1 uppercase tsn {}  @apply hover:text-primary-2 hover:border-current {}  @apply px-5 py-[10px] lg:py-2 xl:px-10 flex gap-4 {} }
  .btn-lined.is-green {
    @apply text-primary-2 hover:text-primary-1 {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary-2 rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    @media (max-width: 767.98px) {
      #buttonMenu .line {
        @apply bg-white {} } }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply hidden {} }
  #buttonMenu .bar {
    @apply bg-primary-2 rounded-1 absolute shadow-bar {} }
    @media (max-width: 767.98px) {
      #buttonMenu .bar {
        @apply bg-white {} } }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.active .line:nth-child(2) {
    @apply opacity-0 -translate-x-2 {} }
  #buttonMenu.active .line:nth-of-type(1) {
    @apply rotate-45 top-[15px] {} }
  #buttonMenu.active .line:nth-of-type(3) {
    @apply -rotate-45 bottom-[15px] {} }
  #buttonMenu.active .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  @apply fixed z-50 size-full top-0 left-0 bg-black/75 backdrop-blur hidden {} }
  .backdrop.backdrop-mobile {
    @apply top-[60px] xl:top-0 {} }

.cta-fixed {
  @apply fixed z-50 bottom-24 right-3 md:bottom-5 xl:bottom-15 xl:right-10 {} }
  .cta-fixed ul {
    @apply flex flex-col gap-2 xl:gap-3 {} }
  .cta-fixed li {
    animation: ctaPhoneRing 1s infinite ease-in-out;
    @apply size-12 rounded-full flex-center overflow-hidden bg-primary-2 text-white text-xl transition-all hover:bg-primary-2 xl:size-15 xl:text-2xl {} }
    .cta-fixed li a {
      @apply size-full flex-center p-2 {} }
    .cta-fixed li.back-to-top {
      @apply bg-neutral-500 opacity-0 pointer-events-none hover:bg-neutral-700 {} }
      .cta-fixed li.back-to-top.active {
        @apply opacity-100 pointer-events-auto {} }
  .cta-fixed .ActionMenu {
    left: auto !important;
    right: 0 !important; }

.social-list ul {
  @apply flex flex-wrap gap-4 {} }

.social-list a {
  @apply size-12 flex-center rounded-full border border-white text-white text-xl p-2 tsn {}  @apply hover:-translate-y-2 {} }

.social-list img, .social-list svg {
  @apply w-full h-full object-contain {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply relative px-4 py-2 sub-header-20 font-bold text-primary-2 uppercase cursor-pointer h-12 border border-primary-2 {} }
    .dropdown .dropdown-toggle::after {
      @apply font-awesome content-['\f078'] font-light text-xl text-current ml-3 {} }
    .dropdown .dropdown-toggle.active::after {
      @apply content-['\f077'] {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full left-0 w-full min-w-max z-10 bg-white px-5 py-3 space-y-2 shadow hidden {} }
    .dropdown .dropdown-menu a {
      @apply text-15px text-neutral-950 py-2 block hover:text-primary-2 {} }

.same-custom-fancybox.fancybox__container .fancybox__content {
  max-width: 41.66667rem;
  @apply p-0 w-full rounded-2 {} }

.same-custom-fancybox.fancybox__container [data-fancybox-close] {
  @apply hidden {} }

.same-custom-fancybox.fancybox__container [class*='btn'] {
  @apply rounded-1 {} }

.popup-error.fancybox__container .fancybox__content, .popup-form.fancybox__container .fancybox__content {
  @apply py-6 px-4 sm:px-8 {} }

.popup-success.fancybox__container .fancybox__content {
  max-width: 47.91667rem; }

.popup-form .form-group input, .popup-form .form-group select, .popup-form .form-group textarea {
  @apply border-primary-2 {} }

.popup-form .form-group select {
  background-size: 1.45833rem;
  background-position: center right 0.20833rem; }

.popup-form .field-required {
  @apply text-primary-1 {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea,
select {
  @apply bg-white border border-white pl-5 pr-10 py-2 rounded-none text-sm font-normal leading-normal text-primary-2 w-full bg-no-repeat h-10 m-0 {}  @apply focus:outline-none focus:ring-2 focus:ring-secondary-3 focus:ring-offset-0 {}  @apply placeholder:uppercase placeholder:text-neutral-300 {}  @apply bg-neutral-200 {} }
  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  textarea:focus,
  select:focus {
    @apply bg-white {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  @apply placeholder:text-neutral-300 {}  @apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900 {}  @apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900 {} }
  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus {
    @apply bg-white {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23cfcfcf' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>");
  @apply bg-right appearance-none {} }

select[multiple] {
  @apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none {} }

textarea {
  @apply h-[120px] py-2 xl:h-40 block {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-primary-2 flex-center gap-2 text-sm text-white px-3 py-2 cursor-pointer xl:pr-10 whitespace-nowrap {} }
    input[type='file'] ~ label i {
      @apply text-lg {} }

.attach-file {
  @apply flex items-center {} }
  .attach-file .file-preview {
    @apply px-3 text-sm text-neutral-700 xl:px-5 {}    @apply w-full {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f192'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-400 mb-2 {}    @apply font-medium {}    @apply section-header-32 font-bold text-primary-2 mb-3 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal {} }
  .form-group .attach-file {
    @apply bg-neutral-50 z-1 {}    @apply w-full mt-5 {} }
  .form-group button[type="submit"], .form-group input[type="submit"] {
    @apply px-5 py-3 h-10 tsn xl:h-12 xl:px-10 {} }
  .form-group.form-rating > label {
    @apply mb-0 text-base font-normal text-[#545454] xl:text-xl {} }

.gl-star-rating--stars {
  @apply flex items-center gap-[2px] xl:rem:gap-[10px] {} }
  .gl-star-rating--stars span {
    @apply flex-center {} }
    .gl-star-rating--stars span::before {
      @apply font-awesome content-['\f005'] font-bold text-xl text-primary-2/30 xl:text-2xl {} }
    .gl-star-rating--stars span.gl-active, .gl-star-rating--stars span.gl-selected {
      @apply before:text-primary-1 {} }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.invalid-feedback {
  @apply text-sm text-primary-1 font-medium mt-1 {} }

.warning-feedback {
  @apply text-sm text-primary-2 font-medium mt-1 {} }

.contact-success-message {
  @apply text-sm text-green-700 font-medium mt-1 {} }

.form-select-dropdown {
  @apply relative {} }
  .form-select-dropdown.active .easy-dropdown-list {
    @apply opacity-100 pointer-events-auto {} }
  .form-select-dropdown input {
    background-image: url('data:image/svg+xml,<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="white"/></svg>');
    background-position: center right 0.83333rem;
    background-size: 0.83333rem 0.46875rem;
    height: auto !important;
    @apply bg-no-repeat block w-full {}    @apply placeholder:text-inherit {} }
    .form-select-dropdown input:read-only {
      @apply bg-inherit {} }
    .form-select-dropdown input:disabled {
      @apply opacity-70 {} }
  .form-select-dropdown .fake-input {
    @apply whitespace-nowrap overflow-hidden {}    @apply absolute inset-0 {}    @apply bg-white text-primary-2 invisible {}    @apply flex items-center {} }
  .form-select-dropdown .easy-dropdown-list {
    max-height: 14.58333rem; }
  .form-select-dropdown.active-marquee .fake-input {
    @apply visible {} }

.modal {
  @apply bg-transparent bg-none p-0 {} }
  .modal .modal-wrap {
    @apply bg-white p-5 rounded-2 shadow {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-8 xl:mt-10 {} }
  .pagination a {
    @apply flex-center size-8 text-base font-bold text-[#cecece] bg-white rounded-none border border-[#999999]/15 {}    @apply hover:bg-neutral-100 {} }
    .pagination a.active {
      @apply bg-black/10 border-transparent {} }

@screen xl {
  .pagination .prev {
    @apply mr-2 {} }
  .pagination .next {
    @apply ml-2 {} } }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic {
  width: auto !important;
  @apply translate-x-0 {} }
  .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    left: 0 !important;
    transform: scale(1);
    display: none; }
    .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next + .swiper-pagination-bullet, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next:nth-last-child(2) {
      font-size: 0;
      @apply pointer-events-none flex {} }
      .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev:after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next + .swiper-pagination-bullet:after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next:nth-last-child(2):after {
        content: '...';
        @apply text-base {} }
    .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:first-child, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:last-child {
      display: flex !important;
      font-size: calc(16/1920*100rem) !important;
      @apply pointer-events-auto {} }
    .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-main, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-prev, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:first-child, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:last-child {
      @apply text-base pointer-events-auto flex {} }
      .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-main::after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next::after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-prev::after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:first-child::after, .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet:last-child::after {
        display: none; }
    .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic.swiper-pagination-bullets-dynamic .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next:nth-last-child(2) {
      @apply flex {} }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 w-3 h-3 rounded-full bg-secondary-3/50 {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-secondary-3 {} }
  .swiper-pagination .swiper-pagination-bullet.number {
    @apply flex-center size-8 text-base font-normal text-neutral-300 bg-white rounded-none border border-neutral-300 {}    @apply hover:bg-neutral-100 {} }
    .swiper-pagination .swiper-pagination-bullet.number.swiper-pagination-bullet-active {
      @apply bg-neutral-100 text-neutral-500 {} }
  .swiper-pagination .swiper-pagination-bullet.hidden {
    display: none; }
    .swiper-pagination .swiper-pagination-bullet.hidden.swiper-pagination-bullet-active {
      @apply flex {} }
  .swiper-pagination .swiper-pagination-bullet.show-more {
    font-size: 0;
    @apply pointer-events-none {} }
    .swiper-pagination .swiper-pagination-bullet.show-more:after {
      content: '..';
      @apply text-base {} }

.swiper-pagination.is-white .swiper-pagination-bullet {
  @apply bg-white/50 {} }
  .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white {} }

.swiper-button > * {
  @apply text-2xl text-white w-14 h-14 rounded-full border border-secondary-3 bg-secondary-3 flex-center transition-all cursor-pointer hover:text-neutral-900 {} }
  .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 {} }
  .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper-button.is-white > * {
  @apply text-white bg-white/20 border-white hover:text-neutral-200 {} }

.swiper-button.is-abs > * {
  @apply absolute top-1/2 -translate-y-1/2 z-1 {} }

.swiper-button.is-abs .button-prev {
  @apply -left-12 xl:-left-20 {} }

.swiper-button.is-abs .button-next {
  @apply -right-12 xl:-right-20 {} }

.swiper-button.is-abs.is-top-20 > * {
  @apply top-[20%] {} }

.swiper-button.is-abs.is-top-30 > * {
  @apply top-[30%] {} }

.swiper-button.is-abs.is-top-40 > * {
  @apply top-[40%] {} }

.swiper-button .button-prev:hover, .swiper-button .button-next:hover {
  @apply border-primary-1 text-primary-1 {} }

.swiper-relative {
  @apply lg:px-10 xl:px-0 {} }
  .swiper-relative .swiper-slide {
    @apply h-auto {} }
  .swiper-relative.is-page {
    @apply pb-10 lg:pb-0 {} }
  .swiper-relative .swiper-scrollbar {
    @apply h-[4px] bg-neutral-200 rounded-none left-0 w-full {} }
    .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
      @apply bg-primary-1 hover:bg-primary-2 rounded-none {} }

.swiper-page-button {
  @apply flex-center gap-3 xl:gap-5 {} }
  .swiper-page-button .button-prev, .swiper-page-button .button-next {
    @apply size-8 flex-center text-base border border-neutral-300 text-neutral-300 tsn {}    @apply hover:text-primary-1 hover:border-primary-1 {} }
  .swiper-page-button .swiper-pagination {
    @apply static flex-center w-max {} }
    .swiper-page-button .swiper-pagination.swiper-pagination-lock {
      @apply -mx-3 xl:-mx-5 {} }
  .swiper-page-button .swiper-button-lock {
    @apply h-0 overflow-hidden border-0 {}    @apply -mt-5 {} }

@media (min-width: 1200px) {
  .swiper-shadow .swiper {
    @apply p-4 -m-4 {} } }

@screen xl {
  .absolute-button {
    @apply absolute right-0 top-0 {} } }

.arrow-buttons .button-prev, .arrow-buttons .button-next {
  @apply absolute top-1/2 -translate-y-1/2 {}  @apply flex items-center justify-center z-10 {}  @apply size-[44px] xl:rem:size-[44px] {} }
  .arrow-buttons .button-prev.swiper-button-lock, .arrow-buttons .button-next.swiper-button-lock {
    @apply opacity-0 {} }
  .arrow-buttons .button-prev i, .arrow-buttons .button-next i {
    @apply text-[2rem] {} }

.arrow-buttons .button-prev {
  @apply -left-4 xl:-left-20 {} }

.arrow-buttons .button-next {
  @apply -right-4 xl:-right-20 {} }

.marquee {
  overflow: hidden;
  display: flex; }

.service-item {
  @apply h-full flex flex-col {} }
  .service-item .content {
    @apply flex-1 flex flex-col h-full {} }
    .service-item .content .title {
      @apply flex-1 {} }

@media (min-width: 1200px) {
  .bn-1 {
    outline: 0.52083rem solid transparent;
    @apply transition-all {} }
    .bn-1:hover {
      outline-color: white;
      box-shadow: 0 0.20833rem 0.83333rem 0 rgba(0, 0, 0, 0.6);
      @apply bg-white {} } }

.bn-1 .info {
  @apply flex-1 flex flex-col {}  @apply mt-0 {} }
  .bn-1 .info .title {
    @apply flex-1 {} }

.bn-1 .title {
  @apply text-base {} }

.bn-1 .title, .bn-1 .des {
  @apply text-justify {} }

.bn-1 .des {
  @apply flex-none text-15px {} }
  @media (max-width: 767.98px) {
    .bn-1 .des {
      @apply leading-[1rem] {} } }

@media (max-width: 767.98px) {
  .bn-1 .btn-long-arrow {
    @apply mt-4 {} } }

.btn-long-arrow {
  @apply transition-all {} }
  .btn-long-arrow img:nth-of-type(2) {
    @apply hidden {} }
  .btn-long-arrow:hover {
    @apply text-primary-2 gap-10 {} }
    .btn-long-arrow:hover img:nth-of-type(1) {
      @apply hidden {} }
    .btn-long-arrow:hover img:nth-of-type(2) {
      @apply block {} }

@media (max-width: 767.98px) {
  .bn-2 .des {
    @apply hidden {} }
  .bn-2 .btn-long-arrow {
    @apply mt-4 {} } }

.bn-2 .title {
  @apply text-base {} }

.bn-1 .des, .bn-1 .content, .bn-2 .des, .bn-2 .content, .bn-3 .des, .bn-3 .content {
  @apply text-justify {} }

@media (max-width: 767.98px) {
  .bn-1 .title, .bn-2 .title, .bn-3 .title {
    @apply text-[15px] {} }
  .bn-1 .des, .bn-2 .des, .bn-3 .des {
    @apply text-[14px] {} } }

@media (max-width: 767.98px) {
  .home-news .swiper-wrapper, .our-doctor-section .swiper-wrapper {
    transform: none !important;
    @apply flex-col {} }
  .home-news .swiper-slide, .our-doctor-section .swiper-slide {
    margin-right: 0 !important;
    width: 100% !important; }
    .home-news .swiper-slide + .swiper-slide, .our-doctor-section .swiper-slide + .swiper-slide {
      @apply mt-2 {} }
    .home-news .swiper-slide:not(:first-child) .bn-1, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 {
      @apply flex-row gap-4 {} }
      .home-news .swiper-slide:not(:first-child) .bn-1 .img, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .img {
        @apply w-[120px] md:w-[200px] pt-[38px] {} }
      .home-news .swiper-slide:not(:first-child) .bn-1 .info, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .info {
        @apply pt-[10px] mt-0 {} }
      .home-news .swiper-slide:not(:first-child) .bn-1 .des, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .des {
        @apply flex-auto {} }
      .home-news .swiper-slide:not(:first-child) .bn-1 .btn-long-arrow, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .btn-long-arrow {
        @apply mt-3 {} }
    .home-news .swiper-slide:not(:first-child) .bn-1 .title, .home-news .swiper-slide:not(:first-child) .bn-2 .title, .home-news .swiper-slide:not(:first-child) .bn-3 .title, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .title, .our-doctor-section .swiper-slide:not(:first-child) .bn-2 .title, .our-doctor-section .swiper-slide:not(:first-child) .bn-3 .title {
      @apply text-[12px] {} }
    .home-news .swiper-slide:not(:first-child) .bn-1 .des, .home-news .swiper-slide:not(:first-child) .bn-2 .des, .home-news .swiper-slide:not(:first-child) .bn-3 .des, .our-doctor-section .swiper-slide:not(:first-child) .bn-1 .des, .our-doctor-section .swiper-slide:not(:first-child) .bn-2 .des, .our-doctor-section .swiper-slide:not(:first-child) .bn-3 .des {
      @apply text-[14px] {} }
  .home-news .swiper-page-button, .home-news .swiper-button, .our-doctor-section .swiper-page-button, .our-doctor-section .swiper-button {
    display: none !important; }
  .home-news .swiper-wrapper, .our-doctor-section .swiper-wrapper {
    @apply grid-cols-1 gap-y-4 {} } }

@media (max-width: 767.98px) {
  .news-list-1-section-3 .bn:first-child {
    @apply flex-col {} }
    .news-list-1-section-3 .bn:first-child .img {
      @apply max-w-full {} }
      .news-list-1-section-3 .bn:first-child .img a {
        @apply pt-[calc(180/266*100%)] {} }
  .news-list-1-section-3 .bn:not(:first-child) .img {
    @apply w-[120px] md:w-[200px] pt-[38px] {} }
    .news-list-1-section-3 .bn:not(:first-child) .img a {
      @apply pt-[calc(166/266*100%)] {}      @apply h-auto !important {} }
  .news-list-1-section-3 .bn:not(:first-child) .info {
    @apply pt-[10px] mt-0 {} }
  .news-list-1-section-3 .bn:not(:first-child) .des {
    @apply flex-auto {} }
  .news-list-1-section-3 .bn-1:not(:first-child) .title, .news-list-1-section-3 .bn-2:not(:first-child) .title, .news-list-1-section-3 .bn-3:not(:first-child) .title {
    @apply text-[12px] {} }
  .news-list-1-section-3 .bn-1:not(:first-child) .des, .news-list-1-section-3 .bn-2:not(:first-child) .des, .news-list-1-section-3 .bn-3:not(:first-child) .des {
    @apply text-[14px] {} } }

.faq-item {
  @apply flex gap-2 xl:gap-5 {} }
  .faq-item .icon {
    @apply size-15 bg-white rounded-full flex-center xl:size-20 {} }
  .faq-item .caption {
    @apply flex-1 text-15px font-normal text-white {} }
  .faq-item .title {
    @apply sub-header-20 font-bold text-white {} }

.box-faq {
  @apply bg-primary-2 p-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:py-10 {} }

.accordion-item {
  @apply px-3 bg-white border-2 border-gray-200 {} }
  .accordion-item .accordion-head {
    @apply flex items-center justify-between gap-3 py-3 cursor-pointer {} }
  .accordion-item .accordion-title {
    @apply text-15px font-bold text-black {} }
  .accordion-item .accordion-icon {
    @apply size-8 flex-center text-lg text-primary-2 {} }
  .accordion-item .accordion-content {
    @apply body-14 font-normal text-black pb-3 hidden {} }
  .accordion-item.active .accordion-icon::before {
    @apply content-['\f068'] {} }

.hospital-item {
  @apply flex flex-col md:flex-row md:odd:flex-row-reverse {} }
  @media (max-width: 767.98px) {
    .hospital-item {
      @apply flex-col-reverse {} } }
  .hospital-item .image {
    @apply aspect-[402/291] md:w-1/2 {} }
    @media (max-width: 575.98px) {
      .hospital-item .image {
        @apply w-full h-[200px] {} }
        .hospital-item .image img {
          @apply w-full h-full object-cover {} } }
  .hospital-item .caption {
    @apply p-4 bg-primary-2 tsn md:p-8 xl:p-15 md:flex-1 {} }
    .hospital-item .caption * {
      @apply text-white tsn {} }
  .hospital-item .title {
    @apply section-header-30 font-bold uppercase {} }
    @media (max-width: 767.98px) {
      .hospital-item .title {
        @apply text-[20px] leading-[1.2] {} } }
  .hospital-item .line {
    @apply mt-2 mb-3 border-t-2 border-white w-10 xl:mt-3 xl:mb-5 {} }
    @media (max-width: 767.98px) {
      .hospital-item .line {
        @apply border-t-[2px] my-1 {} } }
  .hospital-item .sub-title {
    @apply text-15px font-bold {} }
  .hospital-item .desc {
    @apply mt-5 text-15px font-normal {} }
    @media (max-width: 575.98px) {
      .hospital-item .desc {
        @apply hidden {} } }
  .hospital-item .button {
    @apply mt-5 {} }
    .hospital-item .button a {
      @apply uppercase text-base font-normal {} }
  .hospital-item:hover .caption {
    @apply bg-primary-1 {} }

.hospital-section .full-content {
  @apply text-neutral-700 {}  @apply prose-spacing-0 {} }

.testimonial-top {
  @apply text-center relative xl:rem:max-w-[650px] xl:ml-7 {} }
  .testimonial-top .content {
    @apply section-header-32 font-normal text-black italic mb-2 {} }
  .testimonial-top .author {
    @apply section-header-32 font-bold text-primary-2 {} }
  .testimonial-top .sub-title {
    @apply sub-header-20 font-normal text-primary-2 {} }

@screen xl {
  .testimonial-top::before {
    content: url(../img/quote-left.png);
    @apply absolute -left-7 top-0 {} }
  .testimonial-top .content::after {
    content: url(../img/quote-right.png);
    @apply ml-auto mr-3 block text-right {} } }

.testimonial-item {
  @apply flex gap-2 p-4 border border-neutral-400/30 xl:gap-5 xl:px-8 xl:pb-8 {} }
  .testimonial-item .avatar {
    @apply size-12 xl:size-15 {} }
  .testimonial-item .caption {
    @apply flex-1 {} }
  .testimonial-item .content {
    @apply font-normal text-neutral-700/70 mb-5 {}    @apply text-15px {} }
    .testimonial-item .content p:first-child::before {
      content: url(../img/quote.png);
      @apply mr-3 {} }
  .testimonial-item .author {
    @apply text-15px font-bold text-primary-2 {} }
  .testimonial-item .sub-title {
    @apply body-14 font-normal text-neutral-950/70 {} }
    @media (max-width: 767.98px) {
      .testimonial-item .sub-title {
        @apply hidden {} } }

.testimonial-list {
  @apply grid grid-cols-1 gap-5 md:grid-cols-2 xl:gap-8 {} }

.banner-child .image {
  @apply aspect-[1920/600] {} }
  @media (max-width: 767.98px) {
    .banner-child .image {
      height: 170px;
      @apply aspect-auto {} } }

.banner-child .global-breadcrumb {
  @apply absolute top-0 left-0 w-full z-1 bg-transparent bg-none {} }
  @media (max-width: 767.98px) {
    .banner-child .global-breadcrumb {
      @apply mb-0 {} } }

.banner-child .swiper-pagination {
  @apply xl:bottom-8 {} }
  .banner-child .swiper-pagination .swiper-pagination-bullet {
    @apply bg-white xl:size-4 xl:mx-3 {} }
    .banner-child .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-primary-2 {} }

.banner-child .caption {
  @apply absolute-center z-1 w-full {} }

.banner-child.banner-testimonial .caption {
  @apply static translate-x-0 translate-y-0 py-3 {} }

@screen xl {
  .banner-child.banner-testimonial .caption {
    @apply absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 {} } }

@media (max-width: 767.98px) {
  .banner-child .header-48 {
    @apply text-[20px] {} } }

.home-banner .image {
  @apply aspect-[1920/600] {} }

@media (max-width: 767.98px) {
  .home-banner .swiper-button .button-prev, .home-banner .swiper-button .button-next {
    @apply bg-black/10 border-transparent {} } }

.home-banner-mobile .image {
  @apply aspect-[526/357] {} }

@media (max-width: 767.98px) {
  .home-banner-mobile .swiper-button .button-prev, .home-banner-mobile .swiper-button .button-next {
    @apply bg-black/10 border-transparent {} } }

.searchbox {
  @apply relative container px-0 {} }
  .searchbox input {
    @apply w-full h-12 border-neutral-200 border rounded-1 pl-5 pr-12 text-base text-neutral-700 placeholder:text-neutral-200 focus:outline-none focus:border-primary-2 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-secondary-3 tsn {} }

.search-result-item {
  @apply relative {} }
  .search-result-item .image {
    @apply aspect-[345/189] {} }
  .search-result-item .caption {
    @apply pt-3 xl:pt-5 {} }
  .search-result-item .title {
    @apply section-header-32 font-bold text-primary-2 {} }

.search-result {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-10 xl:gap-x-20 xl:gap-y-12 {} }

.search-section .searchbox {
  @apply flex justify-between gap-3 xl:gap-10 {} }
  .search-section .searchbox .searchinput {
    @apply text-black/50 px-0 border-x-0 border-t-0 rounded-none border-b-2 border-neutral-300 flex-1 xl:h-20 text-xl {}    @apply focus:ring-0 {} }
  .search-section .searchbox .searchbutton {
    @apply static flex-center border-4 border-neutral-300 text-neutral-300 translate-x-0 translate-y-0 xl:size-12 xl:text-3xl {}    @apply hover:bg-neutral-300 hover:text-white {} }

.global-breadcrumb {
  @apply bg-white {} }
  @media (max-width: 767.98px) {
    .global-breadcrumb:not(.breadcrumb-doctor) {
      @apply mb-[-16px] {} } }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center py-3 gap-x-2 gap-y-2 xl:py-5 {} }
    .global-breadcrumb .breadcrumb a {
      @apply block text-sm leading-normal font-bold text-primary-2 uppercase {} }
      .global-breadcrumb .breadcrumb a.active {
        @apply text-primary-1 {} }
    .global-breadcrumb .breadcrumb li {
      @apply flex items-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        content: '/';
        @apply pl-2 text-primary-2 text-base leading-none {} }

.contact-form .form-group input {
  @apply h-12 {} }

.contact-form .form-group textarea {
  @apply xl:h-30 {} }

.contact-form .form-group input, .contact-form .form-group textarea {
  @apply bg-[#ebebeb] {} }
  .contact-form .form-group input:focus, .contact-form .form-group textarea:focus {
    @apply bg-white {} }
  .contact-form .form-group input:valid, .contact-form .form-group textarea:valid {
    @apply outline-none ring-2 ring-secondary-3 ring-offset-0 {}    @apply bg-white {} }

.contact-form .form-group button[type="submit"] {
  @apply ml-auto mr-0 {} }

.contact-form .form-group label {
  @apply body-16 font-bold text-neutral-300 {} }

.rating-wrap .form-group textarea {
  @apply bg-neutral-200 {} }
  .rating-wrap .form-group textarea:focus {
    @apply bg-white {} }
  .rating-wrap .form-group textarea:valid {
    @apply outline-none ring-2 ring-secondary-3 ring-offset-0 {}    @apply bg-white {} }

.rating-wrap .form-group button[type="submit"] {
  @apply ml-auto mr-0 {} }

.rating-wrap .form-group.form-rating {
  @apply flex items-center justify-between {} }

@screen xl {
  .rating-wrap .row {
    @apply -mx-15 {} }
    .rating-wrap .row > * {
      @apply px-15 {} } }

.contact-maps {
  @apply aspect-[1369/446] {} }

.contact-section .block-content {
  @apply text-[#757575] {} }
  @media (max-width: 767.98px) {
    .contact-section .block-content [class*='sub-header'] {
      @apply text-[16px] leading-[1.3] {} } }

.box-profile-detail {
  @apply p-4 border-2 border-neutral-200 sticky top-30 xl:p-5 {} }
  @media (max-width: 767.98px) {
    .box-profile-detail {
      @apply border-[2px] {} } }
  .box-profile-detail table {
    @apply w-full {} }
  .box-profile-detail td {
    @apply body-16 font-normal text-black py-2 border-b border-neutral-300 {}    @apply last:text-right {} }
  .box-profile-detail tr:last-child td {
    @apply py-3 {} }

.post-navigation {
  @apply flex justify-between gap-5 {} }
  .post-navigation > * {
    @apply relative flex items-center gap-3 xl:gap-5 {} }
    .post-navigation > *.next {
      @apply flex-row-reverse {} }
  .post-navigation .icon {
    @apply size-11 flex-center border border-neutral-300 text-neutral-300 text-lg {}    @apply transition-all duration-300 {} }
  .post-navigation .caption {
    @apply flex flex-col text-[10px] text-neutral-300 {} }

@screen md {
  .post-navigation .caption {
    @apply body-14 {} } }
  .post-navigation .title {
    @apply text-neutral-300 {}    @apply text-[12px] {} }

@screen md {
  .post-navigation .title {
    @apply body-16 {} } }
  .post-navigation .link {
    background: none; }
    .post-navigation .link:hover .icon {
      @apply border-primary-1 bg-primary-1 text-white {} }

.find-doctor-detail-section .full-content {
  @apply text-black {} }

@media (max-width: 1023.98px) {
  .find-doctor-detail-section .container > .row {
    @apply flex-col-reverse gap-y-5 {} } }

@media (min-width: 768px) {
  .our-doctor-section .expand-btn {
    display: none !important; } }

.our-doctor-section .swiper-relative .swiper {
  @apply xl:-m-5 {}  @apply xl:p-5 {} }

.our-doctor-section .doctor-item::before {
  box-shadow: 3px 0px calc(10/1920*100rem) rgba(0, 0, 0, 0.2); }

.our-doctor-section .swiper-page-button {
  @apply xl:mt-0 xl:top-[0.3rem] {} }

.our-doctor-section .swiper-button {
  @apply flex-center gap-3 mt-5 xl:mt-0 {} }
  .our-doctor-section .swiper-button > * {
    @apply size-11 text-lg border-2 border-neutral-300 rounded-none bg-transparent bg-none text-neutral-300 {} }

@screen xl {
  .our-doctor-section .swiper-button {
    @apply absolute right-0 bottom-[calc(100%+(60/1920*100rem))] {} } }

@screen xl {
  .our-doctor-section.button-position-2 .swiper-button {
    @apply right-0 bottom-[calc(100%+(20/1920*100rem))] {} } }

@screen xl {
  .our-doctor-section.button-position-3 .swiper-button {
    @apply right-0 bottom-[calc(100%+(8/1920*100rem))] top-[unset] {} } }

.box-doctor-filter {
  @apply bg-primary-2 p-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:gap-10 xl:py-5 {} }
  .box-doctor-filter select {
    @apply text-primary-1/40 uppercase {} }
    .box-doctor-filter select option {
      @apply text-primary-2 font-medium {} }

.doctor-item {
  @apply relative flex md:flex-col z-1 h-full {} }
  @media (max-width: 767.98px) {
    .doctor-item {
      @apply border-y border-neutral-200 bg-white {} } }
  .doctor-item::before {
    content: '';
    box-shadow: 3px 0px calc(50/1920*100rem) rgba(0, 0, 0, 0.2);
    @apply absolute-center w-[calc(100%+20px)] h-[calc(100%+20px)] bg-white -z-1 opacity-0 pointer-events-none tsn {} }
  .doctor-item * {
    @apply tsn {} }
  .doctor-item .caption {
    @apply flex flex-col h-full {} }
    @media (max-width: 767.98px) {
      .doctor-item .caption {
        @apply flex-1 p-0 h-auto {} } }
  .doctor-item .image {
    @apply image-ratio pt-[100%] w-full {} }
    @media (max-width: 767.98px) {
      .doctor-item .image {
        flex: 0 0 150px;
        @apply size-[150px] pt-0 {} } }
    @media (max-width: 767.98px) {
      .doctor-item .image {
        flex: 0 0 100px;
        @apply size-[100px] {} } }
    .doctor-item .image img {
      @apply absolute w-full h-full top-0 left-0 {} }
  .doctor-item .name {
    @apply body-18 font-bold text-primary-2 {}    @apply line-clamp-1 {} }
    @media (max-width: 767.98px) {
      .doctor-item .name {
        @apply text-[15px] {} } }
  @media (max-width: 767.98px) {
    .doctor-item .link-arrow {
      @apply text-[12px] {} } }
  .doctor-item .sub-title {
    @apply body-16 text-black/50 {}    @apply line-clamp-1 {} }
    @media (max-width: 767.98px) {
      .doctor-item .sub-title {
        @apply text-[13px] {} } }
  .doctor-item .title {
    @apply body-16 font-bold text-black {} }
  .doctor-item .desc {
    @apply line-clamp-4 {} }
    @media (max-width: 767.98px) {
      .doctor-item .desc {
        @apply hidden {} } }
  .doctor-item .top {
    @apply flex-1 {}    @apply bg-white tsn p-2 border border-neutral-200 xl:px-4 {} }
    @media (max-width: 767.98px) {
      .doctor-item .top {
        @apply border-0 flex-none pb-0 {} } }
  .doctor-item .bot {
    @apply bg-white tsn p-2 border border-neutral-200 xl:px-4 xl:pb-5 {}    @apply flex flex-col {} }
    @media (max-width: 767.98px) {
      .doctor-item .bot {
        @apply border-0 {} } }
    .doctor-item .bot .button {
      @apply flex-1 items-end {} }
  .doctor-item .desc {
    @apply body-14 font-normal text-black/80 {}    @apply h-[70px] xl:rem:h-[70px] {} }
  .doctor-item .button {
    @apply mt-1 md:mt-3 {} }
    .doctor-item .button a {
      @apply uppercase {} }
  .doctor-item:hover {
    @apply before:opacity-100 before:pointer-events-auto {} }
    .doctor-item:hover * {
      @apply text-white {} }
    .doctor-item:hover .top {
      @apply bg-primary-2 {} }
      @media (max-width: 767.98px) {
        .doctor-item:hover .top {
          @apply flex-1 pb-2 {} } }
    .doctor-item:hover .bot {
      @apply bg-primary-1 {} }
    .doctor-item:hover .sub-title {
      @apply text-white/50 {} }
    .doctor-item:hover .link-arrow {
      @apply text-white {}      @apply gap-10 {} }

.box-find-doctor-content {
  @apply xl:rem:max-w-[1050px] mx-auto {} }

.find-doctor-section .doctor-list {
  @apply grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 md:gap-10 {} }
  .find-doctor-section .doctor-list + .button .btn-lined {
    @apply border-2 border-neutral-200 text-primary-1 uppercase xl:text-xl xl:h-15 xl:rem:min-w-[400px] {}    @apply hover:border-current {} }

.header-top {
  @apply hidden items-start justify-end rem:pb-[3px] rem:pt-[10px] sm:flex {} }
  @media (max-width: 575.98px) {
    .header-top {
      @apply hidden !important {} } }
  .header-top > * {
    @apply xl:last:mr-12 {} }

.header-bot {
  @apply md:border-t border-neutral-100 py-2 xl:rem:py-[10px] h-[60px] xl:h-auto {}  @apply relative z-30 {}  @apply flex items-center {} }
  .header-bot .header-wrap {
    @apply flex justify-between rem:max-w-[1432px] rem:px-[15px] mx-auto {}    @apply w-full {} }
    .header-bot .header-wrap .main-menu {
      @apply h-full {} }
      .header-bot .header-wrap .main-menu > li {
        @apply h-full flex items-center {} }
  .header-bot .header-left {
    @apply flex items-center justify-between gap-2 {} }

@screen xl {
  .header-bot .header-left {
    width: calc(273/1400*100%); } }
    @media (max-width: 767.98px) {
      .header-bot .header-left {
        @apply h-full {} }
        .header-bot .header-left img {
          @apply py-[5px] {} } }
  .header-bot .header-center {
    @apply flex-1 hidden xl:block {}    @apply rem:my-[-10px] {} }
  .header-bot .header-right {
    @apply flex items-center justify-end gap-3 {} }
    @media (max-width: 767.98px) {
      .header-bot .header-right {
        @apply w-full {}        @apply relative {} } }

header {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  @apply fixed xl:relative bg-white top-0 left-0 w-full z-999 {} }
  @media (max-width: 767.98px) {
    header {
      background-color: rgba(153, 163, 166, 0.5); }
      header::after {
        content: '';
        background-image: url(../img/mobile-nav.jpg);
        @apply absolute inset-0 bg-no-repeat bg-cover -z-1 {}        @apply pointer-events-none {}        @apply transition-all {}        @apply bg-center {} } }
  header .menu-top a, header .button-email a {
    @apply block rem:pb-[11px] body-14 font-normal text-neutral-700 {}    @apply hover:text-primary-2 hover-underline {} }
  header .menu-top li.active a, header .button-email li.active a {
    @apply text-primary-2 hover-underline-active {} }
  header .button-115 a {
    @apply h-[32px] rem:px-[10px] flex-center rounded-1 body-14 font-normal text-white bg-primary-1 hover:bg-primary-2 xl:h-6 {} }
    header .button-115 a .icon {
      @apply md:hidden {} }
      header .button-115 a .icon ~ * {
        @apply hidden md:block {} }
  header .button-phone a, header .button-login a {
    @apply body-14 font-bold text-neutral-700 uppercase flex-center rem:gap-[9px] rem:pb-[11px] {}    @apply hover:text-primary-2 hover-underline {} }
    @media (max-width: 767.98px) {
      header .button-phone a, header .button-login a {
        @apply text-white {} } }

@screen md {
  header .button-email, header .button-115 {
    @apply rem:ml-[25px] {} } }
  header .button-phone {
    @apply ml-15 {} }
  @media (max-width: 767.98px) {
    header #buttonMenu {
      @apply absolute left-0 top-1/2 -translate-y-1/2 {} } }
  header .button-login {
    @apply rem:ml-[23px] {} }
  header .logo {
    @apply h-10 md:h-12 xl:h-15 {} }
    @media (max-width: 767.98px) {
      header .logo {
        @apply absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-2 {} } }
    header .logo a {
      @apply h-full {} }
    header .logo img {
      @apply w-auto max-h-none {} }
  header .second-logo {
    @apply h-[60px] xl:rem:h-[60px] {}    @apply flex {} }
    header .second-logo a {
      @apply h-full {} }
    header .second-logo:before {
      content: '';
      @apply static {}      @apply border-l border-l-neutral-200 pr-1 sm:pr-3 {} }
    header .second-logo img {
      @apply w-auto max-h-none h-full object-contain {} }
  header .main-menu {
    @apply flex-center gap-5 {} }
    header .main-menu .dropdown {
      @apply absolute top-full left-0 z-10 {}      @apply bg-white w-full py-10 border-t border-neutral-950 {}      @apply transition-all duration-300 {}      @apply opacity-0 pointer-events-none {} }
    header .main-menu .dropdown-wrapper {
      max-width: 72.91667rem;
      @apply w-full mx-auto {} }
      header .main-menu .dropdown-wrapper > ul {
        @apply grid grid-cols-3 gap-10 {} }
        header .main-menu .dropdown-wrapper > ul a {
          @apply flex flex-col {}          @apply text-left items-start {}          @apply body-18 font-bold text-primary-2 {} }
          header .main-menu .dropdown-wrapper > ul a .img {
            padding-top: 62.27273%;
            @apply relative h-0 overflow-hidden w-full block {} }
            header .main-menu .dropdown-wrapper > ul a .img img, header .main-menu .dropdown-wrapper > ul a .img iframe, header .main-menu .dropdown-wrapper > ul a .img video {
              object-fit: cover; }
            header .main-menu .dropdown-wrapper > ul a .img img {
              @apply w-full h-full object-cover absolute top-0 left-0 {} }
    header .main-menu a {
      @apply body-14 font-bold text-neutral-700 uppercase flex-center rem:pb-[11px] rem:mt-[11px] gap-2 {}      @apply hover:text-primary-2 hover-underline {} }
    header .main-menu .toggle-icon {
      @apply hidden {} }
    header .main-menu li.active > a {
      @apply text-primary-2 hover-underline-active {} }
    header .main-menu li[class*='has-children']:hover > ul {
      @apply opacity-100 pointer-events-auto {} }
    header .main-menu li[class*='has-children']:hover > .dropdown {
      @apply opacity-100 pointer-events-auto {} }
    header .main-menu li[class*='has-children'] > ul {
      min-width: 220px;
      @apply absolute top-full left-0 bg-white border-none space-y-1 z-1 {}      @apply shadow-drop-shadow-light {}      @apply p-3 {}      @apply opacity-0 transition-all duration-300 pointer-events-none {} }
      header .main-menu li[class*='has-children'] > ul a {
        @apply py-2 justify-start {} }
  header .button-search {
    @apply flex-center size-[32px] border border-primary-2 text-primary-2 text-[14px] cursor-pointer tsn xl:rem:size-[26px] xl:text-sm {} }

@screen xl {
  header .button-search {
    @apply hover:bg-primary-2 hover:text-white {} } }
    @media (max-width: 767.98px) {
      header .button-search {
        @apply bg-transparent border-white text-white {} } }

.mobile-wrap {
  @apply fixed top-[60px] sm:top-[101px] w-screen bg-white shadow-drop-shadow-light z-[1001] pt-[14px] pl-[16px] pb-[16px] max-w-[390px] -left-full opacity-0 tsn pointer-events-none hidden xl:hidden {}  @apply overflow-auto {}  @apply h-[calc(100vh-60px)] sm:h-[calc(100vh-101px)] {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-[16px] flex flex-col {} }
  .mobile-wrap .toggle-submenu {
    @apply absolute right-0 z-1 flex justify-end items-center size-[60px] top-0 {} }
    .mobile-wrap .toggle-submenu::before {
      @apply font-awesome content-['\f054'] text-[16px] font-light text-primary-2 tsn {} }
    .mobile-wrap .toggle-submenu.active::before {
      @apply rotate-90 {} }
  .mobile-wrap .button-search {
    @apply hover:text-primary-2 hover-underline {}    @apply h-[60px] flex items-center {} }
    .mobile-wrap .button-search i {
      @apply text-[18px] text-primary-2 py-4 {} }
      @media (max-width: 767.98px) {
        .mobile-wrap .button-search i {
          @apply size-10 border border-primary-2 flex items-center justify-center {} } }
  .mobile-wrap .main-menu, .mobile-wrap .menu-top {
    @apply uppercase {} }
    .mobile-wrap .main-menu li a, .mobile-wrap .menu-top li a {
      @apply block {} }
    .mobile-wrap .main-menu li.active > a, .mobile-wrap .menu-top li.active > a {
      @apply font-bold underline !important {} }
    .mobile-wrap .main-menu > li, .mobile-wrap .menu-top > li {
      @apply border-b border-primary-2/20 relative {} }
      .mobile-wrap .main-menu > li > a, .mobile-wrap .menu-top > li > a {
        @apply inline-block text-[18px] leading-normal font-normal text-primary-2 uppercase {}        @apply py-[16px] w-full {} }
      .mobile-wrap .main-menu > li > .sub-menu, .mobile-wrap .menu-top > li > .sub-menu {
        @apply p-3 hidden {}        @apply border-t border-t-primary-2/50 {} }
      .mobile-wrap .main-menu > li[class*='has-children'] > a, .mobile-wrap .menu-top > li[class*='has-children'] > a {
        @apply flex items-center justify-between {} }
      .mobile-wrap .main-menu > li .dropdown, .mobile-wrap .menu-top > li .dropdown {
        @apply hidden rounded-none {}        @apply p-3 border-t border-t-primary-2/50 {} }
        .mobile-wrap .main-menu > li .dropdown ul .img, .mobile-wrap .menu-top > li .dropdown ul .img {
          @apply hidden {} }
        .mobile-wrap .main-menu > li .dropdown ul a, .mobile-wrap .menu-top > li .dropdown ul a {
          @apply text-15px py-3 text-primary-2 {} }
        .mobile-wrap .main-menu > li .dropdown ul li.active a, .mobile-wrap .menu-top > li .dropdown ul li.active a {
          @apply text-primary-2 {} }
      .mobile-wrap .main-menu > li .toggle-icon, .mobile-wrap .menu-top > li .toggle-icon {
        @apply size-10 -my-2 relative z-1 {} }
        .mobile-wrap .main-menu > li .toggle-icon svg, .mobile-wrap .menu-top > li .toggle-icon svg {
          @apply w-full h-full {} }
  .mobile-wrap .sub-menu > li > a {
    @apply text-[16px] font-medium text-primary-2 {}    @apply py-3 {} }
  .mobile-wrap .sub-menu > li > a, .mobile-wrap .dropdown-wrapper > ul > li > a {
    @apply flex items-center gap-2 {} }
    .mobile-wrap .sub-menu > li > a::before, .mobile-wrap .dropdown-wrapper > ul > li > a::before {
      content: '';
      @apply static {}      @apply border-t-[6px] border-l-[8px] border-b-[6px] {}      @apply border-l-primary-2 bg-transparent border-t-transparent border-b-transparent {} }
  .mobile-wrap .button-email, .mobile-wrap .button-phone, .mobile-wrap .button-login {
    @apply border-b border-primary-2/20 relative {} }
    .mobile-wrap .button-email a, .mobile-wrap .button-phone a, .mobile-wrap .button-login a {
      @apply flex items-center gap-2 py-[16px] text-[18px] font-normal text-primary-2 uppercase {} }
  .mobile-wrap.active {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-32 left-0 right-0 w-full z-[1005] hidden px-10 xl:rem:top-[160px] {} }
  .search-wrap .close-search {
    @apply xl:hidden {} }
  @media (max-width: 1279.98px) {
    .search-wrap {
      @apply top-[60px] sm:top-[101px] h-[calc(100vh-60px)] sm:h-[calc(100vh-101px)] {}      @apply bg-white {} }
      .search-wrap .close-search {
        @apply absolute top-0 right-0 size-[4rem] flex items-center justify-center {} }
        .search-wrap .close-search i {
          @apply text-[3rem] font-bold text-neutral-800 {} }
      .search-wrap .searchbox {
        @apply flex gap-3 w-full items-center h-full {} }
        .search-wrap .searchbox input {
          box-shadow: none;
          @apply bg-white text-[20px] sm:text-[1.7rem] md:text-[2.5rem] outline-0 border-transparent outline-transparent focus:outline-0 focus:border-0 focus:border-b-[3px] p-0 rounded-none {}          @apply border-0 border-b-[3px] border-b-primary-2 {}          @apply h-[4rem] {} }
      .search-wrap .searchbutton {
        flex: 0 0 4rem;
        @apply size-[4rem] text-[2.5rem] static transform-none {}        @apply border-[3px] border-primary-2 {} } }

.language_bar_list a {
  @apply size-[25px] rounded-full block overflow-hidden {}  @apply border border-primary-2 {} }
  .language_bar_list a img {
    width: 100% !important;
    height: 100% !important;
    @apply object-cover block {} }

.wpml-ls.wpml-ls-legacy-dropdown-click {
  @apply w-full relative {} }
  .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li {
    @apply flex-center {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li img {
      @apply size-5 rounded-full w-full h-full object-cover {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a {
      @apply flex-center text-base font-normal text-neutral-500 bg-none bg-transparent p-0 border-0 {} }
      .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a::after {
        @apply font-awesome content-['\f078'] text-xs font-light text-current ml-1 border-none flex static {} }
        @media (max-width: 767.98px) {
          .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a::after {
            @apply text-white {} } }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > .wpml-ls-sub-menu {
      @apply absolute top-full left-0 bg-white p-1 hidden border-none space-y-1 {} }
      .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > .wpml-ls-sub-menu li {
        @apply flex-center {} }

@media (max-width: 1023.98px) and (max-width: 1023.98px) {
  .customer-services .list {
    @apply grid-cols-1 gap-y-10 {} } }

@media (max-width: 1023.98px) {
  .customer-services .bn-1 {
    @apply flex-row gap-4 {} }
    .customer-services .bn-1 .img {
      @apply w-[120px] md:w-[200px] {} }
    .customer-services .bn-1 .info {
      @apply pt-0 mt-0 {} }
    .customer-services .bn-1 .des {
      @apply flex-auto {} } }

.homepage .booking-nav-small {
  @apply hidden {} }

.homepage .sticky-wrapper {
  @apply absolute w-full {}  @apply pointer-events-none {} }
  @media (max-width: 1199.98px) {
    .homepage .sticky-wrapper {
      @apply hidden {} } }
  .homepage .sticky-wrapper.is-sticky {
    @apply pointer-events-auto {} }
    .homepage .sticky-wrapper.is-sticky .booking-nav-small {
      @apply block {} }

@media (min-width: 1200px) {
  .home-nav .nav-wrapper {
    box-shadow: 0.20833rem 0.20833rem 1.66667rem 0.83333rem rgba(0, 0, 0, 0.08); } }

@media (max-width: 1199.98px) {
  .home-nav .nav-wrapper {
    max-width: 500px; } }

@media (min-width: 1200px) {
  .home-nav .item {
    @apply bg-white {}    @apply rem:max-w-[270px] {} }
    .home-nav .item:nth-of-type(1) {
      @apply bg-secondary-3 {} }
    .home-nav .item:nth-of-type(2) {
      @apply bg-secondary-5 {} }
    .home-nav .item:nth-of-type(3) {
      @apply bg-secondary-4 {} }
    .home-nav .item:nth-of-type(4) {
      @apply bg-primary-2 {} } }

@media (max-width: 1199.98px) {
  .home-nav .item {
    width: 50%; }
    .home-nav .item:nth-of-type(1) {
      @apply order-2 {} }
    .home-nav .item:nth-of-type(2) {
      @apply order-3 {} }
    .home-nav .item:nth-of-type(3) {
      @apply order-4 {} }
    .home-nav .item:nth-of-type(4) {
      @apply order-5 {} }
    .home-nav .item:nth-of-type(5) {
      @apply order-1 {}      @apply w-full {} }
    .home-nav .item:nth-of-type(6) {
      @apply order-7 w-full {} } }

@media (max-width: 767.98px) {
  .home-nav .item {
    @apply w-full {} } }

@media (max-width: 1199.98px) {
  .home-nav .item .title, .home-nav .item .description {
    @apply hidden {} } }

.home-nav .form-group {
  @apply relative {} }
  .home-nav .form-group .datepicker {
    @apply h-full {} }
  .home-nav .form-group input {
    @apply border border-white bg-transparent {}    @apply body-14 pr-8 pl-4 xl:pl-2 py-3 md:py-5 xl:py-2 {}    @apply text-white block {}    @apply h-auto {}    @apply font-bold uppercase placeholder:text-white {} }
    @media (max-width: 767.98px) {
      .home-nav .form-group input {
        @apply text-[12px] {} } }
  .home-nav .form-group::after {
    @apply font-awesome text-15px font-light text-white {}    @apply absolute top-1/2 -translate-y-1/2 right-2 lg:right-3 pointer-events-none {} }
    @media (max-width: 767.98px) {
      .home-nav .form-group::after {
        top: 2px;
        @apply translate-y-0 {}        @apply text-[24px] {} } }
  .home-nav .form-group.form-date::after {
    content: '\f133'; }
  .home-nav .form-group.form-department::after {
    content: '\f013'; }
  .home-nav .form-group.form-doctor::after {
    content: '\f82f'; }
  .home-nav .form-group.form-time::after {
    content: '\f017'; }
  .home-nav .form-group.form-time .dropdown-list {
    @apply p-1 {} }
    .home-nav .form-group.form-time .dropdown-list ul {
      @apply grid grid-cols-4 gap-1 {} }
    .home-nav .form-group.form-time .dropdown-list li {
      @apply border-b border-b-primary-2 {} }
      .home-nav .form-group.form-time .dropdown-list li.disabled {
        @apply border-b border-b-red-500 {} }
  .home-nav .form-group.form-time .name {
    @apply text-center {} }
  @media (min-width: 1200px) {
    .home-nav .form-group.form-phone input {
      @apply placeholder:text-primary-2/50 text-primary-2 {}      @apply border-neutral-200 {} } }
  .home-nav .form-group.form-phone::after {
    content: '';
    background-image: url(../img/home/phone.svg);
    @apply bg-no-repeat bg-center bg-contain {}    @apply w-[24px] h-[24px] lg:w-[14px] lg:h-5 xl:rem:w-[10px] xl:rem:h-[16px] {} }
    @media (max-width: 1199.98px) {
      .home-nav .form-group.form-phone::after {
        @apply brightness-0 invert {} } }
    @media (max-width: 767.98px) {
      .home-nav .form-group.form-phone::after {
        @apply right-[5px] top-[8px] {} } }
  .home-nav .form-group.active .dropdown-list {
    @apply opacity-100 pointer-events-auto {} }

.home-nav .dropdown-list {
  max-height: 40vh; }
  .home-nav .dropdown-list li.disabled {
    @apply opacity-50 pointer-events-none {} }

@keyframes anima-mb {
  0% {
    transform: translateY(3px); }
  100% {
    transform: translateY(-3px); } }

@media (max-width: 1279.98px) {
  .home-nav .show-nav-mobile .title {
    @apply flex items-center gap-2 justify-center {} } }
  @media (max-width: 1279.98px) and (max-width: 767.98px) {
    .home-nav .show-nav-mobile .title {
      @apply text-[16px] py-[8px] {} } }

@media (max-width: 1279.98px) {
    .home-nav .show-nav-mobile .title::after {
      content: '';
      background-image: url(../img/icon-booking.png);
      animation: .7s infinite alternate anima-mb;
      @apply bg-no-repeat bg-contain bg-center {}      @apply size-[24px] block {} } }

.home-nav .show-nav-mobile.active {
  @apply opacity-100 pointer-events-auto {} }

@media (max-width: 767.98px) {
  .home-nav .btn-booking {
    @apply py-3 {} }
  .home-nav .text-5xl {
    @apply text-[24px] {} } }

.air-datepicker {
  --adp-width: 100%;
  @apply border border-primary-2 {} }

@screen sm {
  .air-datepicker {
    --adp-width: 320px; } }

@screen xl {
  .air-datepicker {
    --adp-width: calc(320/1920*100rem); } }
  .air-datepicker .air-datepicker--pointer::after {
    @apply border-primary-2 {} }
  .air-datepicker .air-datepicker-body--cells {
    grid-auto-rows: 1fr;
    @apply gap-1 {} }
    .air-datepicker .air-datepicker-body--cells.-months- {
      @apply grid-cols-4 {} }
  .air-datepicker .air-datepicker-cell, .air-datepicker .air-datepicker-body--day-names > div {
    @apply aspect-square {} }
  .air-datepicker .air-datepicker--content {
    @apply p-2 {} }
  .air-datepicker .air-datepicker-cell {
    @apply relative {} }
    .air-datepicker .air-datepicker-cell::before {
      content: '';
      height: 0.0625rem;
      bottom: -0.125rem;
      @apply absolute w-full left-0 bg-secondary-3 {} }
    .air-datepicker .air-datepicker-cell.-disabled- {
      @apply opacity-50 {} }
      .air-datepicker .air-datepicker-cell.-disabled-::before {
        @apply bg-primary-1 {} }
    .air-datepicker .air-datepicker-cell.-selected- {
      @apply text-white bg-primary-2 {} }
  .air-datepicker .air-datepicker-body--day-name {
    @apply text-primary-2 {} }

.datepicker {
  @apply w-full {} }

.datepicker__wrapper.datepicker__wrapper.datepicker__wrapper {
  @apply border border-secondary-3 {} }
  @media (max-width: 767.98px) {
    .datepicker__wrapper.datepicker__wrapper.datepicker__wrapper {
      @apply hidden w-full {} } }

.booking-nav-small {
  @apply transition-all duration-300 {} }

@screen xl {
  .booking-nav-small {
    @apply py-5 bg-neutral-50 {} } }
  .booking-nav-small.active {
    @apply shadow-lg shadow-primary-2/50 {} }
  .booking-nav-small .nav-wrapper {
    @apply shadow-none {} }

@screen xl {
  .booking-nav-small .nav-wrapper {
    @apply rem:gap-x-[11px] {} } }

@screen xl {
  .booking-nav-small .item {
    @apply rem:w-[225px] {}    @apply p-0 {} } }
  .booking-nav-small .item .title, .booking-nav-small .item .description {
    @apply hidden {} }
  .booking-nav-small .item .form-group {
    @apply mt-0 {} }

@screen xl {
  .booking-nav-small .item .form-group {
    @apply h-full {} }
    .booking-nav-small .item .form-group input {
      @apply h-full {} } }
  .booking-nav-small .item:nth-of-type(5) .btn-booking {
    @apply hidden {} }
  .booking-nav-small .item.item-submit-btn {
    @apply block {} }
    .booking-nav-small .item.item-submit-btn .btn-booking {
      @apply rem:py-[10px] {} }

.home-news.section {
  @apply pt-7 xl:rem:pt-[30px] {} }

@media (max-width: 767.98px) {
  .home-news {
    @apply pt-0 {} } }

.home-news .swiper-slide {
  @apply h-auto {} }

@screen lg {
  .home-news .col-left {
    max-width: calc(838/1190*100%); } }

.home-news .form-group input {
  @apply border border-white bg-transparent {}  @apply rem:py-[19px] pl-4 text-white {} }
  @media (max-width: 767.98px) {
    .home-news .form-group input {
      @apply border-[2px] {} } }

.home-news .form-group span {
  @apply px-4 {} }

.home-news .btn-submit {
  @apply body-16 font-bold w-full bg-white {}  @apply text-primary-1 block uppercase {}  @apply border border-transparent {}  @apply md:rem:py-[19px] py-[10px] px-4 {} }
  @media (max-width: 767.98px) {
    .home-news .btn-submit {
      @apply text-[15px] py-[8px] {} } }
  .home-news .btn-submit:hover {
    @apply text-white bg-primary-2 {}    @apply border-white {} }

@screen xl {
  .home-news .swiper-page-button {
    @apply absolute top-0 right-0 {} } }

@media (min-width: 768px) {
  .home-news .expand-btn {
    display: none !important; } }

.home-user {
  min-height: 31.25rem; }
  @media (max-width: 767.98px) {
    .home-user {
      min-height: 555px; } }
  .home-user .content {
    padding: 20px; }

@screen md {
  .home-user .content {
    max-width: 20.83333rem; } }

@screen lg {
  .home-user .content {
    max-width: 24.01042rem;
    padding: 3.125rem; } }
    .home-user .content::before, .home-user .content::after {
      content: '';
      width: 40px;
      height: 20px;
      @apply absolute bg-no-repeat bg-center bg-contain {} }

@screen lg {
  .home-user .content::before, .home-user .content::after {
    width: 3.125rem;
    height: 2.08333rem; } }
    .home-user .content::before {
      background-image: url(../img/quote-left.png);
      @apply left-0 top-0 {} }
    .home-user .content::after {
      background-image: url(../img/quote-right.png);
      @apply bottom-0 right-0 {} }
  @media (max-width: 767.98px) {
    .home-user .play-btn {
      @apply top-[80%] translate-y-0 {} } }
  @media (max-width: 767.98px) {
    .home-user .title {
      @apply font-normal {}      @apply text-[24px] {} }
    .home-user [class*='section-header-32'] {
      @apply text-[30px] {} } }
  .home-user.play-video .wrapper {
    @apply top-0 -translate-y-full {}    @apply opacity-0 pointer-events-none {} }
  .home-user.play-video .content {
    @apply -translate-x-1/2 {}    @apply opacity-0 pointer-events-none {} }
  .home-user.play-video .video {
    @apply opacity-100 {} }
  .home-user.play-video .play-btn {
    @apply top-[80%] translate-y-0 {} }
  .home-user .container .swiper-relative, .home-user .container .swiper, .home-user .container .swiper-wrapper, .home-user .container .swiper-slide {
    @apply h-full w-full {} }
  .home-user .swiper-slide {
    @apply flex flex-col {} }

.home-map {
  height: 23.54167rem; }
  .home-map iframe {
    @apply w-full h-full {} }

.box-career-info {
  @apply flex flex-col gap-10 bg-white border border-neutral-200 p-4 md:px-10 md:justify-between md:flex-row md:items-center xl:px-20 {} }
  .box-career-info .button {
    @apply md:flex-col md:gap-1 {} }
    .box-career-info .button a {
      @apply normal-case {} }

.box-career-detail {
  @apply flex flex-col gap-10 xl:flex-row xl:justify-between {} }
  .box-career-detail .left {
    @apply xl:flex-1 {} }
  .box-career-detail .right {
    @apply xl:rem:w-[250px] {} }
    .box-career-detail .right ul {
      @apply border border-primary-2 p-5 space-y-3 xl:sticky xl:top-30 {} }
    .box-career-detail .right li {
      @apply flex items-center gap-2 {} }
    .box-career-detail .right .icon {
      @apply size-10 flex-center {} }
      .box-career-detail .right .icon img {
        @apply w-full h-full object-contain {} }
    .box-career-detail .right .caption {
      @apply flex-1 {} }

.career-detail-section {
  @apply pt-5 pb-10 md:pt-0 {} }
  .career-detail-section .box-career-info {
    @apply md:-mt-20 {} }
  .career-detail-section .full-content {
    @apply xl:px-3 {} }
    .career-detail-section .full-content h3 {
      @apply text-xl font-bold text-primary-2 uppercase {} }

.contact-need-item {
  @apply bg-current p-4 text-center text-primary-1/80 rem:mt-[30px] xl:p-10 xl:mt-10 {} }
  .contact-need-item .icon {
    @apply size-15 rounded-full mx-auto rem:-mt-[46px] bg-primary-1/80 flex-center mb-5 xl:size-20 xl:-mt-20 {} }
  .contact-need-item a {
    @apply hover-underline inline-block {} }

.contact-need-list {
  @apply grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:rem:gap-[34px] {} }
  .contact-need-list .contact-need-item:nth-child(n+2) {
    @apply bg-primary-2/80 {} }
    .contact-need-list .contact-need-item:nth-child(n+2) .icon {
      @apply bg-primary-2/80 {} }

.career-item {
  @apply flex items-center tsn hover:bg-neutral-50 xl:gap-5 xl:px-5 {} }
  .career-item .icon {
    @apply size-20 flex-center {} }
  .career-item .title {
    @apply body-18 font-bold text-primary-2 uppercase {} }
  .career-item .caption {
    @apply flex-1 py-2 {} }

.box-career-slider .swiper-slide {
  @apply border-l border-t border-neutral-100 md:flex md:flex-wrap {} }
  .box-career-slider .swiper-slide .career-item {
    @apply border-b border-r border-neutral-100 md:w-1/2 {} }

.career-form {
  @apply flex flex-col gap-5 p-5 md:px-10 md:flex-row xl:px-15 xl:rem:min-h-[580px] xl:gap-10 {} }
  .career-form .left {
    @apply md:flex-1 {} }

@screen xl {
  .career-form .left {
    @apply pt-10 pl-10 {} } }
  .career-form .right {
    @apply md:rem:w-[580px] md:mt-5 xl:mt-15 {} }
  @media (max-width: 767.98px) {
    .career-form [class*='sub-header-24'] {
      @apply text-[18px] {} } }

.news-latest-item {
  @apply relative flex gap-4 {} }
  .news-latest-item .image {
    @apply aspect-square w-20 {} }
  .news-latest-item .caption {
    @apply flex-1 {} }
  .news-latest-item .news-date {
    @apply text-neutral-300 mb-1 {} }
  .news-latest-item .title {
    @apply text-15px font-bold text-black/80 {} }
  .news-latest-item:hover .title {
    @apply text-primary-1 {} }

.news-detail-image img {
  @apply h-auto {} }

.action-list {
  @apply flex items-center gap-2 xl:gap-5 {} }
  .action-list a {
    @apply flex-center gap-1 {} }
    .action-list a.heart {
      @apply hover:text-primary-1 {} }
      .action-list a.heart.active {
        @apply text-primary-1 {} }
        .action-list a.heart.active i::before {
          @apply font-bold {} }

.interact-list {
  @apply flex items-center gap-4 xl:gap-5 {} }
  .interact-list a {
    @apply flex items-center gap-2 text-15px text-neutral-500 hover:text-primary-2 {} }
    .interact-list a.active {
      @apply text-primary-1 {} }
      .interact-list a.active i::before {
        @apply font-bold {} }

.author-list {
  @apply flex items-center gap-2 xl:gap-5 {} }
  .author-list li {
    @apply body-14 {} }

.comment-form-item {
  @apply flex gap-2 xl:gap-5 {} }
  .comment-form-item .comment-avatar {
    @apply size-15 overflow-hidden xl:size-20 {} }
    .comment-form-item .comment-avatar img {
      @apply size-full object-cover {} }
  .comment-form-item .comment-form {
    @apply flex-1 {} }
    .comment-form-item .comment-form textarea {
      @apply px-2 {} }
    .comment-form-item .comment-form button {
      @apply ml-auto mr-0 {} }

.comment-item {
  @apply flex gap-2 xl:gap-5 {} }
  .comment-item .comment-avatar {
    @apply size-15 overflow-hidden xl:size-20 {} }
    .comment-item .comment-avatar img {
      @apply size-full object-cover {} }
  .comment-item .comment-content {
    @apply flex-1 {} }
  .comment-item .comment-body {
    @apply body-14 text-black {} }
  .comment-item .comment-footer {
    @apply flex items-center gap-4 {} }
    .comment-item .comment-footer a {
      @apply body-14 text-neutral-500 hover:text-primary-1 {} }
      .comment-item .comment-footer a.active {
        @apply text-primary-1 {} }
        .comment-item .comment-footer a.active i::before {
          @apply font-bold {} }
  .comment-item .comment-reply .comment-avatar {
    @apply size-14 {} }
  .comment-item .comment-form-item .comment-form textarea {
    @apply h-14 {} }
  .comment-item .comment-form-item .comment-form button {
    @apply xl:px-5 xl:h-8 xl:text-xs {} }

.box-comment > .button a {
  @apply w-full bg-neutral-100 text-neutral-700 border-neutral-100 xl:h-12 xl:text-lg {}  @apply hover:bg-neutral-200 hover:border-neutral-200 {} }

.box-news-detail-search .searchbox input {
  @apply w-full h-12 border-neutral-50 rounded-none pl-5 pr-12 text-base text-neutral-700 {}  @apply placeholder:text-black/30 placeholder:normal-case {} }

.box-news-detail-search .searchbox button {
  @apply absolute top-1/2 -translate-y-1/2 right-2 z-1 size-8 border border-black/20 flex-center text-base text-black/20 {}  @apply hover:bg-primary-2 hover:text-white hover:border-primary-2 {} }

.box-latest-news .tabslet-tabs {
  @apply border border-neutral-50 p-3 flex items-center gap-2 justify-between {} }
  .box-latest-news .tabslet-tabs a {
    @apply block pb-1 text-center text-sm font-normal text-black/50 border-b border-transparent hover:border-primary-2 {} }
  .box-latest-news .tabslet-tabs li.active a {
    @apply border-primary-2 text-black font-bold {} }

.box-latest-news .button a {
  @apply w-full {} }

.news-detail-section .swiper-pagination {
  @apply flex-center gap-2 pointer-events-none xl:bottom-5 {} }
  .news-detail-section .swiper-pagination .swiper-pagination-bullet {
    @apply size-3 rounded-full text-0 pointer-events-auto bg-primary-2 border-none {} }
    .news-detail-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-primary-1 {} }

.news-detail-section .button-prev {
  @apply absolute left-0 bottom-0 z-1 size-8 flex-center text-white text-2xl xl:size-13 xl:text-3xl {} }

.news-detail-section .button-next {
  @apply absolute right-0 bottom-0 z-1 size-8 flex-center text-white text-2xl xl:size-13 xl:text-3xl {} }

.news-detail-section .date-action {
  @apply flex items-center justify-between pb-1 border-b border-neutral-300 {} }
  .news-detail-section .date-action .news-date {
    @apply flex items-center gap-2 text-primary-2 text-sm {} }
    .news-detail-section .date-action .news-date i {
      @apply text-lg {} }

.news-detail-section .box-comment {
  @apply mt-10 {} }

.news-detail-section .post-navigation {
  @apply py-3 border-y border-neutral-300 flex items-center justify-end gap-2 text-right xl:gap-3 {} }
  .news-detail-section .post-navigation .icon {
    @apply size-7 text-base {} }
  .news-detail-section .post-navigation .caption {
    @apply text-neutral-500 {} }
  .news-detail-section .post-navigation .title {
    @apply text-neutral-300 {} }
  .news-detail-section .post-navigation .link:hover .title {
    @apply text-primary-2 {} }

.box-news-detail-search {
  @apply hidden {} }

.news-date {
  @apply text-[10px] font-normal text-neutral-300 {} }

.news-category {
  @apply body-18 font-normal text-neutral-300 uppercase {} }

.news-item {
  @apply relative flex flex-col tsn md:flex-row-reverse md:even:flex-row {} }
  .news-item .image {
    @apply aspect-[260/226] md:w-1/2 {} }
  .news-item .caption {
    @apply flex-1 bg-primary-2 p-4 text-white flex flex-col justify-center xl:p-10 {} }
    .news-item .caption * {
      @apply text-white {} }
  .news-item .title {
    @apply sub-header-24 font-bold uppercase {} }
  .news-item .line {
    @apply mt-7 mb-3 border-t-2 border-neutral-300 w-[52%] {} }
  .news-item .news-date {
    @apply text-15px italic {} }
  .news-item .desc {
    @apply text-15px mt-3 {} }
  .news-item:hover {
    box-shadow: 0 0 43px rgba(0, 0, 0, 0.57); }
  .news-item.is-video .image {
    @apply w-full bg-neutral-800 {} }
    .news-item.is-video .image img {
      @apply opacity-50 {} }
    .news-item.is-video .image::before {
      @apply font-awesome-sharp content-['\f04b'] text-5xl font-bold text-white absolute-center z-1 size-25 border-4 border-white flex-center rounded-full {}      @apply xl:size-30 xl:text-6xl {} }
  .news-item.is-video .caption {
    @apply hidden {} }
  .news-item.is-video:hover .image img {
    @apply opacity-100 {} }
  .news-item.is-event .caption {
    @apply bg-primary-1 {} }

.news-list-2-wrap {
  @apply grid grid-cols-1 gap-8 md:grid-cols-3 xl:gap-x-10 {} }

@screen md {
  .news-list-2-wrap .news-item {
    @apply col-span-3 {} }
    .news-list-2-wrap .news-item:nth-child(2), .news-list-2-wrap .news-item:nth-child(5) {
      @apply col-span-1 {} }
    .news-list-2-wrap .news-item:nth-child(3), .news-list-2-wrap .news-item:nth-child(4) {
      @apply col-span-2 {} }
    .news-list-2-wrap .news-item:nth-child(5) .image {
      @apply hidden {} } }

@screen xl {
  .news-list-2-wrap .news-item:nth-child(1) .image, .news-list-2-wrap .news-item:nth-child(6) .image, .news-list-2-wrap .news-item:nth-child(7) .image {
    @apply w-[calc(465/800*100%)] aspect-[465/300] {} }
  .news-list-2-wrap .news-item:nth-child(1) .caption, .news-list-2-wrap .news-item:nth-child(6) .caption, .news-list-2-wrap .news-item:nth-child(7) .caption {
    @apply xl:p-12 {} }
  .news-list-2-wrap .news-item:nth-child(1) .title, .news-list-2-wrap .news-item:nth-child(6) .title, .news-list-2-wrap .news-item:nth-child(7) .title {
    @apply section-header-32 {} }
  .news-list-2-wrap .news-item:nth-child(1) .news-date, .news-list-2-wrap .news-item:nth-child(6) .news-date, .news-list-2-wrap .news-item:nth-child(7) .news-date {
    @apply body-18 {} } }

.news-list-1-section-1 .column-2 .bn-1 .btn-long-arrow {
  @apply hidden {} }

.news-list-1-section-1 .column-2 .bn-1 .time-comment {
  @apply mt-3 {} }

@screen xl {
  .news-list-1-section-1 .column-2 .bn-1 .des {
    @apply mt-5 {} } }

.news-list-1-section-1 .column-3 .bn-1 .btn-long-arrow {
  @apply hidden {} }

.news-list-1-section-1 .column-3 .bn-1 .des {
  @apply hidden {} }

.news-list-1-section-3 .img {
  @apply text-center {} }

.video-item-2:hover .play-btn img {
  @apply scale-100 {} }
  .video-item-2:hover .play-btn img:nth-of-type(1) {
    @apply brightness-0 scale-75 {} }

.video-item {
  @apply relative {} }
  .video-item .image {
    @apply aspect-square {} }
    .video-item .image::before {
      @apply font-awesome-sharp content-['\f04b'] text-5xl font-bold text-white absolute-center z-1 size-25 border-4 border-white flex-center rounded-full {}      @apply xl:size-30 xl:text-6xl {} }
    .video-item .image::after {
      content: '';
      @apply absolute inset-0 bg-black/[.35] {} }
    .video-item .image .sub-title {
      @apply absolute top-1/2 -translate-y-1/2 -right-25 z-1 hidden xl:block border border-neutral-700 bg-white p-5 text-3xl font-bold text-white rem:w-[340px] {} }
      .video-item .image .sub-title span {
        @apply py-8 px-10 bg-primary-2 flex {} }
  .video-item:hover .image::after {
    @apply bg-transparent {} }
  .video-item .caption {
    @apply pt-3 {} }
  .video-item .desc {
    @apply text-15px text-black {} }
  .video-item .news-date {
    @apply flex items-center gap-2 text-black text-sm mb-3 {} }
    .video-item .news-date i {
      @apply text-primary-2 text-lg {} }
  .video-item .title {
    @apply sub-header-20 font-bold text-primary-2 line-clamp-2 {} }
  .video-item .desc {
    @apply text-15px text-neutral-950 line-clamp-3 mt-5 {} }

.news-video-list {
  @apply grid grid-cols-1 gap-10 md:grid-cols-2 xl:gap-x-5 xl:grid-cols-3 {} }

.news-video-section .video-item:nth-child(1) {
  @apply md:col-span-2 xl:col-span-3 {} }
  .news-video-section .video-item:nth-child(1) .image {
    @apply md:aspect-[797/339] overflow-visible {} }
  .news-video-section .video-item:nth-child(1) .news-date {
    @apply hidden {} }
  .news-video-section .video-item:nth-child(1) .title {
    @apply w-max text-primary-1 mx-auto border-b border-[#afafaf] text-center pb-3 px-3 xl:text-4xl {} }
  .news-video-section .video-item:nth-child(1) .desc {
    @apply xl:mb-5 {} }
  .news-video-section .video-item:nth-child(1):hover .image img {
    @apply scale-100 {} }

.news-video-section .btn-lined {
  @apply border-2 border-neutral-950 text-primary-1 uppercase xl:text-xl xl:h-15 xl:px-20 {}  @apply hover:border-current {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply site-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-secondary-3 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-neutral-700 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-neutral-700 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-neutral-700 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.specialist-detail-1 .searchbox input {
  @apply h-auto {}  @apply text-15px text-primary-2 {}  @apply border border-primary-1 {}  @apply h-10 normal-case {}  @apply placeholder:text-primary-2 placeholder:normal-case {} }

.specialist-detail-1 .searchbox button {
  @apply h-full right-0 {} }

@screen xl {
  .specialist-detail-1 .swiper-page-button {
    @apply gap-3 absolute top-0 right-0 {} } }

.specialist-detail-1 .row .col {
  @apply relative {} }

@media (max-width: 1199.98px) {
  .specialist-detail-1 .box-specialist-category {
    top: 101px;
    height: calc(100vh - 101px);
    width: calc(100% - calc(32/1920*100rem));
    @apply absolute top-full left-1/2 -translate-x-1/2 z-[100] {}    @apply flex flex-col max-h-[200px] overflow-auto {}    @apply opacity-0 pointer-events-none {}    @apply transition-all duration-300 {}    @apply p-0 bg-white border border-neutral-100 {} } }
  @media (max-width: 1199.98px) and (min-width: 576px) {
    .specialist-detail-1 .box-specialist-category {
      top: 60px;
      height: calc(100vh - 60px); } }

@media (max-width: 1199.98px) {
    .specialist-detail-1 .box-specialist-category.active {
      @apply opacity-100 pointer-events-auto {} }
    .specialist-detail-1 .box-specialist-category .title {
      @apply hidden {} }
  .specialist-detail-1 .category-list {
    @apply mt-0 pb-0 {} }
    .specialist-detail-1 .category-list li.active a, .specialist-detail-1 .category-list li:hover a, .specialist-detail-1 .category-list li a {
      @apply px-4 {} } }

@media (max-width: 767.98px) {
  .specialist-detail-1 .toggle-category {
    @apply py-1 {} } }

@media (max-width: 767.98px) {
  .specialist-detail-1 .container > [class*='section-header-32'] {
    @apply hidden {} } }

@screen xl {
  .specialist-detail-3 .swiper-page-button {
    @apply absolute right-0 {} } }

.services-detail table {
  @apply w-full {} }
  .services-detail table th {
    @apply bg-primary-2 {}    @apply py-2 {}    @apply rem:px-5 {}    @apply sm:text-base text-sm font-bold text-white {} }
    .services-detail table th:not(:first-child) {
      @apply text-left {} }
  .services-detail table td {
    @apply sm:text-15px text-[12px] text-neutral-700 font-normal {}    @apply py-2 {}    @apply rem:px-[10px] {} }
    .services-detail table td:first-child {
      @apply text-center {} }
    .services-detail table td:not(:first-child) {
      @apply text-left {} }
    .services-detail table td:nth-of-type(3) {
      @apply text-center {} }
  .services-detail table tr ~ tr:nth-of-type(odd) {
    @apply bg-neutral-50 {} }
  .services-detail table th:first-child, .services-detail table td:first-child {
    @apply rem:w-[46px] {} }
  .services-detail table th:not(:first-child), .services-detail table td:not(:first-child) {
    @apply border-l border-l-neutral-200 {} }
  .services-detail table td:nth-of-type(2) {
    width: calc(590/1190*100%); }
  .services-detail table td:nth-of-type(3) {
    width: calc(554/1190*100%); }

.services-detail select {
  @apply text-left {}  @apply pl-3 {} }

@media (max-width: 767.98px) {
  .services-detail [class*='section-header-32'] {
    @apply text-[17px] {} } }

.services-detail .des {
  @apply text-gray-800 {} }

.services-detail .select-category-detail .option {
  @apply font-bold uppercase {} }

@media (max-width: 767.98px) {
  .customer-services-detail {
    @apply text-[14px] !important {} } }

@media (max-width: 1023.98px) {
  .services-list .col-left {
    @apply order-2 {} } }

@media (max-width: 1023.98px) {
  .services-list .col-right {
    @apply order-1 {} } }

@media (max-width: 767.98px) {
  .services-list [class*='section-header-32'] {
    @apply text-[25px] {} } }

.services-list .item .des {
  @apply mt-2 {} }

.box-partner {
  @apply flex flex-col gap-5 lg:flex-row lg:items-center lg:justify-between {} }

.partner-1-section {
  @apply xl:py-12 {} }
  .partner-1-section .site-desc {
    @apply text-justify {} }

.partner-item {
  @apply border border-neutral-200 bg-white px-4 py-2 {} }

.partner-2-category .head {
  @apply border-b border-primary-2 {} }

.partner-2-category .category-title {
  @apply sub-header-20 font-bold text-white bg-primary-2 px-5 py-2 h-12 relative w-max -mb-px {} }
  .partner-2-category .category-title::after {
    content: '';
    @apply size-0 block absolute left-full top-0 pointer-events-none rem:border-b-[48px] border-b-primary-2 rem:border-r-[26px] border-r-transparent {} }

.partner-2-category .partner-list {
  @apply grid grid-cols-2 mt-10 rem:gap-[30px] md:grid-cols-3 lg:grid-cols-4 {} }

.partner-2-category .partner-title {
  @apply sub-header-20 font-bold text-primary-1 {} }

.partner-2-category .partner-group {
  @apply first:mt-3 {} }

.partner-2-section .box-partner {
  @apply items-start {} }
  .partner-2-section .box-partner .left {
    @apply lg:rem:max-w-[855px] {} }

.partner-2-section .category-list {
  @apply grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:rem:-mx-[10px] xl:rem:gap-x-[43px] {} }

.category-item {
  @apply flex flex-col gap-2 tsn relative {}  @apply xl:rem:p-[10px] {} }
  .category-item .image {
    @apply aspect-[355/230] {} }
  .category-item .caption {
    @apply flex-1 {} }
  .category-item .title {
    @apply body-14 font-bold text-primary-2 line-clamp-3 rem:h-[54px] border-b border-neutral-200 {} }
  .category-item .desc {
    @apply rem:mt-[9px] body-14 font-normal text-neutral-700 line-clamp-3 {} }
  .category-item .button {
    @apply mt-3 tsn xl:mt-20 {} }
    .category-item .button > a {
      @apply xl:-translate-y-10 {} }
  .category-item:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    @apply bg-white {} }
    .category-item:hover .button > a {
      @apply xl:translate-y-0 xl:gap-10 xl:text-primary-2 {} }

.footer-pdr {
  @apply md:pr-7 lg:pr-5 xl:pr-7 {} }

@media (max-width: 767.98px) {
  .footer-logo {
    @apply w-[200px] {} } }

.footer-info > * {
  @apply mb-5 last:mb-0 {} }

@media (max-width: 767.98px) {
  .footer-info {
    @apply text-[13px] {} } }

.footer-title {
  @apply body-18 font-bold text-white pb-3 relative {} }
  .footer-title::before {
    content: '';
    @apply absolute left-0 bottom-0 w-11 bg-white/75 h-1 pointer-events-none {} }

.footer-item {
  @apply flex gap-3 relative {} }
  .footer-item .image {
    @apply aspect-[82/58] w-[82px] {} }
    @media (max-width: 767.98px) {
      .footer-item .image {
        @apply w-[33%] aspect-auto h-[58px] {} }
        .footer-item .image img {
          @apply w-full h-full object-cover {} } }
  .footer-item .caption {
    @apply flex-1 {} }
  .footer-item .title {
    @apply body-14 font-bold text-white line-clamp-2 capitalize {} }
  .footer-item .news-date {
    @apply mb-1 {} }

.footer-top {
  @apply xl:pb-5 {} }

.footer-bot .footer-wrap {
  @apply flex items-center flex-wrap justify-between text-center gap-3 xl:gap-5 {} }

.footer-menu {
  @apply flex flex-wrap gap-3 xl:gap-5 {} }
  .footer-menu a {
    @apply label-12 font-normal text-white hover-underline uppercase {} }
  .footer-menu li {
    @apply flex-center {} }
    .footer-menu li.active a {
      @apply hover-underline-active {} }
