.home-banner
	.image
		// @apply aspect-[1350/600] md:aspect-[1920/600]
		@apply aspect-[1920/600]
	.swiper-button
		@media (max-width: 767.98px)
			.button-prev,.button-next
				@apply bg-black/10 border-transparent
.home-banner-mobile
	.image
		@apply aspect-[526/357]
	.swiper-button
		@media (max-width: 767.98px)
			.button-prev,.button-next
				@apply bg-black/10 border-transparent
