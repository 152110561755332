
.homepage
	.booking-nav-small
		@apply hidden
	.sticky-wrapper
		@apply absolute w-full
		@apply pointer-events-none
		@media (max-width: 1199.98px)
			@apply hidden
		&.is-sticky
			@apply pointer-events-auto
			.booking-nav-small
				@apply block
.home-nav
	.nav-wrapper
		@media (min-width: 1200px)
			box-shadow: rr(4) rr(4) rr(32) rr(16) rgba(0, 0, 0, 0.08)
		@media (max-width: 1199.98px)
			max-width: 500px
	.item
		@media (min-width: 1200px)
			@apply bg-white
			@apply rem:max-w-[270px]
			&:nth-of-type(1)
				@apply bg-secondary-3
			&:nth-of-type(2)
				@apply bg-secondary-5
			&:nth-of-type(3)
				@apply bg-secondary-4
			&:nth-of-type(4)
				@apply bg-primary-2
		@media (max-width: 1199.98px)
			width: 50%
			&:nth-of-type(1)
				@apply order-2
			&:nth-of-type(2)
				@apply order-3
			&:nth-of-type(3)
				@apply order-4
			&:nth-of-type(4)
				@apply order-5
			&:nth-of-type(5)
				@apply order-1
				@apply w-full
			&:nth-of-type(6)
				@apply order-7 w-full
		@media (max-width: 767.98px)
			@apply w-full
		.title,.description
			@media (max-width: 1199.98px)
				@apply hidden

	.form-group
		@apply relative
		.datepicker
			@apply h-full
		input
			@apply border border-white bg-transparent
			@apply body-14 pr-8 pl-4 xl:pl-2 py-3 md:py-5 xl:py-2
			@apply text-white block
			@apply h-auto
			@apply font-bold uppercase placeholder:text-white
			@media (max-width: 767.98px)
				@apply text-[12px]
		&::after
			@apply font-awesome text-15px font-light text-white
			@apply absolute top-1/2 -translate-y-1/2 right-2 lg:right-3 pointer-events-none
			@media (max-width: 767.98px)
				top: 2px
				@apply translate-y-0
				@apply text-[24px]
		&.form-date
			&::after
				content: '\f133'
		&.form-department
			&::after
				content: '\f013'
		&.form-doctor
			&::after
				content: '\f82f'
		&.form-time
			&::after
				content: '\f017'
			.dropdown-list
				@apply p-1
				ul
					@apply grid grid-cols-4 gap-1
				li
					@apply border-b border-b-primary-2
					&.disabled
						@apply border-b border-b-red-500
			.name
				@apply text-center

		&.form-phone
			@media (min-width: 1200px)
				input
					@apply placeholder:text-primary-2/50 text-primary-2
					@apply border-neutral-200
			&::after
				content: ''
				background-image: url(../img/home/phone.svg)
				@apply bg-no-repeat bg-center bg-contain
				@apply w-[24px] h-[24px] lg:w-[14px] lg:h-5 xl:rem:w-[10px] xl:rem:h-[16px]
				@media (max-width: 1199.98px)
					@apply brightness-0 invert
				@media (max-width: 767.98px)
					@apply right-[5px] top-[8px]

		&.active
			.dropdown-list
				@apply opacity-100 pointer-events-auto
	.dropdown-list
		max-height: 40vh
		li
			&.disabled
				@apply opacity-50 pointer-events-none
	@keyframes anima-mb
		0%
			transform: translateY(3px)
		100%
			transform: translateY(-3px)

	.show-nav-mobile
		@media (max-width: 1279.98px)
			.title
				@apply flex items-center gap-2 justify-center
				@media (max-width: 767.98px)
					@apply text-[16px] py-[8px]
				&::after
					content: ''
					background-image: url(../img/icon-booking.png)
					animation: .7s infinite alternate anima-mb
					@apply bg-no-repeat bg-contain bg-center
					@apply size-[24px] block

		&.active
			@apply opacity-100 pointer-events-auto
	@media (max-width: 767.98px)
		.btn-booking
			@apply py-3
		.text-5xl
			@apply text-[24px]
.air-datepicker
	--adp-width: 100%
	@apply border border-primary-2
	@screen sm
		--adp-width: 320px
	@screen xl
		--adp-width: calc(320/1920*100rem)
	.air-datepicker--pointer
		&::after
			@apply border-primary-2
	.air-datepicker-body
		&.-months-
	.air-datepicker-body--cells
		grid-auto-rows: 1fr
		@apply gap-1
		&.-months-
			@apply grid-cols-4
	// .air-datepicker-body.-days-
	.air-datepicker-cell,.air-datepicker-body--day-names >div
		@apply aspect-square
	.air-datepicker--content
		@apply p-2
	.air-datepicker-cell
		@apply relative
		&::before
			content: ''
			height: r(1px)
			bottom: r(-2px)
			@apply absolute w-full left-0 bg-secondary-3
		&.-disabled-
			@apply opacity-50
			&::before
				@apply bg-primary-1
		&.-selected-
			@apply text-white bg-primary-2
	.air-datepicker-body--day-name
		@apply text-primary-2

.datepicker
	@apply w-full
.datepicker__wrapper.datepicker__wrapper.datepicker__wrapper
	@apply border border-secondary-3
	@media (max-width: 767.98px)
		@apply hidden w-full

.booking-nav-small
	@apply transition-all duration-300
	@screen xl
		@apply py-5 bg-neutral-50
	&.active
		@apply shadow-lg shadow-primary-2/50
	.nav-wrapper
		@apply shadow-none
		@screen xl
			@apply rem:gap-x-[11px]
	.item
		@screen xl
			@apply rem:w-[225px]
			@apply p-0
		.title,.description
			@apply hidden
		.form-group
			@apply mt-0
			@screen xl
				@apply h-full
				input
					@apply h-full
		&:nth-of-type(5)
			.btn-booking
				@apply hidden
		&.item-submit-btn
			@apply block
			.btn-booking
				@apply rem:py-[10px]

.home-news
	&.section
		@apply pt-7 xl:rem:pt-[30px]
	@media (max-width: 767.98px)
		@apply pt-0
	.swiper-slide
		@apply h-auto
	.col-left
		@screen lg
			max-width: calc(838/1190*100%)
	// select
	// 	background-image: url('data:image/svg+xml,<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="white"/></svg>')
	// 	background-repeat: no-repeat
	// 	background-position: center right rr(16)
	// 	@apply body-16 font-bold text-white h-auto
	// 	@apply bg-transparent border border-white
	// 	@apply rem:py-[19px] pl-4 whitespace-normal
	// 	option
	// 		@apply text-neutral-950
	.form-group
		input
			@apply border border-white bg-transparent
			@apply rem:py-[19px] pl-4 text-white
			@media (max-width: 767.98px)
				@apply border-[2px]
		span
			@apply px-4
	.btn-submit
		@apply body-16 font-bold w-full bg-white
		@apply text-primary-1 block uppercase
		@apply border border-transparent
		@apply md:rem:py-[19px] py-[10px] px-4
		@media (max-width: 767.98px)
			@apply text-[15px] py-[8px]
		&:hover
			@apply text-white bg-primary-2
			@apply border-white
	.swiper-page-button
		@screen xl
			@apply absolute top-0 right-0

	.expand-btn
		@media (min-width: 768px)
			display: none !important
	// @media (max-width: 767.98px)
	// 	.swiper-wrapper
	// 		transform: none !important
	// 		@apply flex-col
	// 	.swiper-slide
	// 		margin-right: 0 !important
	// 		width: 100% !important
	// 		& + .swiper-slide
	// 			@apply mt-2
	// 		&:not(:first-child)
	// 			.bn-1
	// 				@apply flex-row gap-4
	// 				.img
	// 					@apply w-[120px] md:w-[200px] pt-[30px]
	// 				.info
	// 					@apply pt-0 mt-0
	// 				.des
	// 					@apply flex-auto

	// 	.swiper-page-button
	// 		display: none !important
	// 	.swiper-wrapper
	// 		@apply grid-cols-1 gap-y-10

.home-user
	min-height: rr(600)
	@media (max-width: 767.98px)
		min-height: 555px
	.content
		padding: 20px
		@screen md
			max-width: rr(400)
		@screen lg
			max-width: rr(461)
			padding: rr(60)
		&::before,&::after
			content: ''
			width: 40px
			height: 20px
			@apply absolute bg-no-repeat bg-center bg-contain
			@screen lg
				width: rr(60)
				height: rr(40)
		&::before
			background-image: url(../img/quote-left.png)
			@apply left-0 top-0
		&::after
			background-image: url(../img/quote-right.png)
			@apply bottom-0 right-0
	.play-btn
		@media (max-width: 767.98px)
			@apply top-[80%] translate-y-0
	@media (max-width: 767.98px)
		.title
			@apply font-normal
			@apply text-[24px]
		[class*='section-header-32']
			@apply text-[30px]
	&.play-video
		.wrapper
			@apply top-0 -translate-y-full
			@apply opacity-0 pointer-events-none
		.content
			@apply -translate-x-1/2
			@apply opacity-0 pointer-events-none
		.video
			@apply opacity-100
		.play-btn
			@apply top-[80%] translate-y-0
	.container
		.swiper-relative,.swiper,.swiper-wrapper,.swiper-slide
			@apply h-full w-full
	.swiper-slide
		@apply flex flex-col
.home-map
	height: rr(452)
	iframe
		@apply w-full h-full
